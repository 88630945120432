<template>
  <div 
    class="row row-group staff-options"
    :class="(practice.staff.length - 1) == index ? 'last-row row-group-loader-last' : 'row-group-loader'"
  >
    <div 
      v-if="sending"
      class="preloader"
    >
      <i />
    </div>
    <div class="col-sm-3">
      <span class="text-up">
        <strong>Staff</strong>
      </span>
      <div 
        v-if="!staff.invitation_accepted" 
        class="popup-link"
      >
        <router-link 
          :to="{
            name: 'LoginFirstTime'
          }" 
          tag="a" 
          class="badge badge-pill badge-primary"
        >
          Invite Sent
        </router-link>
        <div class="popup-black">
          An email invite has been sent with a link to activate their account, IF your team member has
          difficulty finding their email, they can also click on "First time user?" on the RegimenMD login screen.
        </div>
      </div>
    </div>
    <div 
      v-if="!edit" 
      class="col-sm-9 staff-info pl-0 pr-0"
    >
      <div class="row-large">
        <div class="col-md-9">
          <span>
            {{ staff.first_name }} {{ staff.last_name }}
            <br>
            {{ staff.email }}
          </span>
        </div>
        <div class="col-md-3 action-box">
          <a 
            v-if="canEdit" 
            class="link_action" 
            href="#"
            @click.prevent="edit_staff" 
          >Edit</a>
        </div>
      </div>
    </div>
    <div 
      v-else 
      class="col-sm-9 staff-edition pl-0 pr-0"
    >
      <div class="row-large">
        <ValidationObserver 
          ref="form" 
          v-slot="{ invalid }" 
          class="col pr-0 pl-0" 
          tag="form"
        >
          <div class>
            <p>Staff name and email is never viewable to a client</p>
          </div>
          <div class="form-group">
            <label :for="`name-${index}`">Staff Name</label>
            <input
              :id="`name-${index}`"
              v-model="staff.first_name"
              placeholder="Leslee"
              type="text"
              name="name"
              :readonly="isSuperAdmin() || currentUser.id === staff.id ? false : true"
            >
          </div>
          <validation-provider
            v-slot="{ errors }"
            :name="`Staf email ${index}`"
            rules="email"
            class="form-group"
          >
            <div class="head-tooltip">
              <label :for="`email-${index}`">Staff Email
                <i
                  class="fas fa-question-circle fa-xs"
                  @mouseover="hover(`staff-email-${index}`)" 
                  @mouseleave="hoverOff(`staff-email-${index}`)"
                />
              </label>
              <div 
                :data-tooltip-type="`staff-email-${index}`" 
                class="tooltip-black"
              >
                The staff’s email address is never shared with clients.
              </div>
            </div>
            
            <input
              :id="`email-${index}`"
              v-model="staff.email"
              placeholder="lesleemoss@practice.com"
              type="text"
              :class="errors.length ? 'error' : ''"
              name="email"
            >
            <p class="validation error mt-2">
              {{ errors[0] }}
            </p>
            <p
              v-for="(err, errIdx) in staff.emailErrors"
              :key="errIdx"
              class="validation error mt-2"
            >
              {{ err }}
            </p>
          </validation-provider>
        </ValidationObserver>
      </div>
      <div class="row-large">
        <div class="col-xl-5 mb-xl-0 mb-2">
          <a
            href="#"
            data-toggle="modal"
            data-target="#remove_member"
            @click.prevent="showRemoveModal = true"
          >
            <small>Remove from practice &#8250;</small>
          </a>
        </div>
        <div class="col-xl-7 pr-0 pl-0">
          <div class="text-right">
            <a
              href="#"
              class="btn btn-bordered right-space"
              data-action="cancel"
              @click.prevent="cancel_edit"
            >Cancel</a>
            <a
              href="#"
              class="btn btn-primary autowidth"
              ata-action="save"
              @click.prevent="update"
            >Save</a>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :show-modal="showRemoveModal"
      modal-id="beforeRemove"
      class="modal fade dashboard-modal"
      @hide-modal-window="showRemoveModal = false"
    >
      <img 
        src="../../../../images/warning.png" 
        alt="Warning!" 
        class="warning-icon"
      >
      <h4 class="modal-title text-center">
        Remove Member
      </h4>
      <p class="text-center">
        Are you sure want to remove this team member your practice?
      </p>
      <div class="bottom-cta mb-3">
        <a 
          href="#" 
          class="btn btn-primary"
          @click.prevent="remove" 
        >Remove</a>
      </div>
      <div class="bottom-cta">
        <a
          href="#"
          class="btn btn-bordered w-100"
          data-dismiss="modal"
          @click.prevent="showRemoveModal = false"
        >Cancel</a>
      </div>
    </Modal>
    <Modal
      :show-modal="showCancelModal"
      modal-id="before"
      class="modal fade dashboard-modal"
      @hide-modal-window="showCancelModal = false"
    >
      <img 
        src="../../../../images/warning.png" 
        alt="Warning!" 
        class="warning-icon" 
      >
      <h4 class="modal-title text-center">
        Before you go ...
      </h4>
      <p class="text-center">
        You have unsaved changes. Are you sure you want to continue?
      </p>
      <div class="mb-3">
        <a 
          href="#" 
          class="btn btn-primary w-100"
          @click.prevent="hideModal" 
        >Continue Without Saving</a>
      </div>
      <div class>
        <a
          href="#"
          class="btn btn-bordered w-100"
          data-dismiss="modal"
          @click.prevent="showCancelModal = false"
        >Cancel</a>
      </div>
    </Modal>
    <div 
      v-if="showCancelModal || showRemoveModal" 
      class="modal-backdrop fade show"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../partials/Modal';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  UPDATE_STAFF,
  REMOVE_STAFF,
  SUPER_ADMIN_GET_PRACTICE
} from '@/store/practice/actions.types';

// Component used to show staff member data and edit it
// @group Practice
export default {
  name: 'TeamStaff',
  components: {
    ValidationProvider,
    ValidationObserver,
    Modal
  },
  props: {
    // Staff object
    staff: {
      type: Object,
      default() {
        return {};
      }
    },
    // Physican index in all physicans array
    index: {
      type: Number,
      default: 0,
    },
    // Can current user edit physician
    canEdit: {
      type: Boolean,
      default: false,
    },
    // Is this last staff member in array
    last: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showRemoveModal: false,
      showCancelModal: false,
      sending: false,
      edit: false,
      currentMember: {}
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'practice'])
  },
  methods: {
    isSuperAdmin() {
      return this.currentUser.roles.includes('super_admin');
    },

    // @vuese
    // Used to validate and update physican data
    async update() {
      const valid = await this.isValid();
      if (valid) {
        this.sending = true;
        const data = [];
        if (this.staff.first_name && this.staff.email) {
          data.push(this.staff);
        }
        const staffPromises = [];
        staffPromises.push(this.$store.dispatch(UPDATE_STAFF, data[0]));

        Promise.all(staffPromises)
          .then(() => {
            this.edit = false;
            this.flashSuccess('Staff information updated');
          })
          .catch(err => {
            const errors = err.data.errors;
            const data = JSON.parse(err.config.data);
            const errEmail = data.email;

            if (errors.email) {
              if (this.staff.email == errEmail) {
                this.staff.emailErrors = errors.email;
              }
            }
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },
    // @vuese
    // Used to remove staff data and close form and modal and reload practice info
    remove() {
      this.$store.dispatch(REMOVE_STAFF, this.staff).then(() => {
        this.showRemoveModal = false;
        this.$store.dispatch(SUPER_ADMIN_GET_PRACTICE)
          .then(() => {
            this.flashSuccess('You have successfully removed a staff member from your practice.');
          });
      });
    },

    // @vuese
    // Check if data was changes and we need 
    // to show confimation modal or just hide edit modal
    cancel_edit() {
      if (
        this.currentMember.email == this.staff.email &&
        this.currentMember.first_name == this.staff.first_name
      ) {
        this.edit = false;
      } else {
        this.showCancelModal = true;
      }
    },

    // @vuese
    // Used to store staff data that we already have
    // and show edit form
    edit_staff() {
      this.currentMember.email = this.staff.email;
      this.currentMember.first_name = this.staff.first_name;
      this.edit = true;
    },
    // @vuese
    // Restore data, and hide continue whitout 
    // saving modal and close edit modal
    hideModal() {
      this.staff.email = this.currentMember.email;
      this.staff.first_name = this.currentMember.first_name;
      this.edit = false;
      this.showCancelModal = false;
    },
    // @vuese
    // Used to show tooltip
    // @arg HTML element data type
    hover(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.add('open');
    },
    // @vuese
    // Used to hide tooltip
    // @arg HTML element data type
    hoverOff(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.remove('open');
    },
    // @vuese
    // Used to check if form is valid
    isValid() {
      return this.$refs.form.validate();
    }
  }
};
</script>