<template>
  <div class="content-wrap">
    <h2>Skincare Pro's Top Picks</h2>
    <Notifications />
    <div class="wrap-table border-table pos-rel">
      <div 
        v-if="sending" 
        class="preloader pt-0"
      >
        <i />
      </div>
      <div 
        v-for="physician in topPicks.items"
        :key="physician.id"
        class="row row-group"
      >
        <div class="col-lg-3">
          <span class="text-up">
            {{ physician.name }}
          </span>
          <p>{{ physician.top_picks_products.length }} products</p>
          <p
            v-if="isRole('super_admin') && physician.practice"
          >
            Skincare Pro from {{ physician.practice.name }} practice
          </p>
        </div>
        <div class="col-lg-9 pr-0">
          <div class="row-large">
            <div class="col-md-11 pr-0">
              <div
                v-if="physician.top_picks_products.length == 0"
                class="grid-images"
              >
                <div>
                  This skincare pro hasn't selected their top products yet.
                </div>
              </div>
              <div v-else>
                <h6 class="text-uppercase top-picks-title">
                  Top picks
                </h6>

                <p class="top-picks-subtitle">
                  {{ physician.top_picks_products.filter(product => !product.featured).length }} products
                </p>

                <div class="grid-images">
                  <div
                    v-for="product in physician.top_picks_products.filter(product => !product.featured).sort((a, b) => a.position - b.position)"
                    :key="product.id"
                    class="item-image"
                  >
                    <img
                      v-if="product.image"
                      :src="product.image" 
                      class="product"
                    >
                    <p>{{ product.title }}</p>
                    <span>
                      {{ product.vendor }}
                      <br>
                      ${{ product.variants[0].price }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1 action-box">
              <router-link 
                :to="{
                  name: 'TopPicksEdit',
                  params: {
                    id: physician.id
                  }
                }" 
                tag="a"
                class="link_action"
              >
                Edit Picks
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="topPicks.items && topPicks.items.length == 0"
      >
        No skincare pro yet.
      </div>
    </div>
    <Pagination
      :total-rows="topPicks.total_items"
      :total-pages="topPicks.total_pages"
      :current-page="currentPage"
      :per-page="per"
      @change-per-page="changePerPage"
      @change-page="changePage"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { encodeQueryData } from '@/helper';
import { GET_TOP_PICKS } from '@/store/top-picks/actions.types';
import Pagination from '../partials/Paginations';

// @vuese
// @group Top Picks
export default {
  name: 'TopPicks',
  components: {
    Pagination,
  },
  data() {
    return {
      sending: false,
      filters: {
      },
      currentPage: 1,
      per: 20,
      selectedPractice: '',
      loadOnInit: false,
    };
  },
  computed: {
    ...mapGetters([
      'topPicks'
    ])
  },
  beforeMount() {
    this.fetchTopPicks();
    if (this.isRole('super_admin')) {
      this.loadOnInit = true;
    }
  },
  methods: {
    // @vuese
    // Used to load top picks
    fetchTopPicks() {
      if (this.sending) return;
      this.sending = true;

      this.$store.dispatch(GET_TOP_PICKS, encodeQueryData(this.buildQueryParams()))
        .finally(() => {
          this.sending = false;
        });
    },
    // @vuese
    // Used to build query object
    buildQueryParams() {
      return {
        per: this.per,
        page: this.currentPage,
        filters: this.filters
      };
    },
    // @vuese
    // Used to change per page and fetch top picks
    // @arg Per page value `Number`
    changePerPage(value) {
      this.scrollToTop();
      this.currentPage = 1;
      this.per = value;
      this.fetchTopPicks();
    },
    // @vuese
    // Used to change page and fetch top picks
    // @arg Value `Number`
    changePage(value) {
      this.scrollToTop();
      this.currentPage = value;
      this.fetchTopPicks();
    },

    scrollToTop() {
      this.$nextTick(() => {
        window.scrollTo({ top: 0, behavior: 'instant' });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .top-picks-title {
    color: #656565;
    margin-bottom: 2px;
  }

  .top-picks-subtitle {
    font-size: 13px;
    font-weight: 100;
  }
</style>