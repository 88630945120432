<template>
  <div class="content-wrap">
    <h2>Preset Regimens</h2>
    <Notifications />
    <div class>
      <PresetRegimenFilters
        @filter-tags="filterTags"
        @filter-creators="filterCreators"
        @reset-all="resetAll"
      />
      <p
        v-if="isRole('super_admin')"
        class="mt-3"
      >
        All preset regimens created here will be shown to all practices.
      </p>
      <hr>
      <div class="tbl-toolbar">
        <div class="toolbar-r text-right">
          <div
            v-show="sending"
            class="preloader"
          >
            <i />
          </div>
          <router-link
            class="action-top"
            :to="{
              name: 'RegimenInfo'
            }"
          >
            <i class="fas fa-plus-circle" /> Create New Preset Regimen
          </router-link>
        </div>
      </div>
      <div class="wrap-table border-table mb-2">
        <div v-if="regimens.items.length < 1">
          <div class="text-center"> No results found. </div>
        </div>
        <div 
        <div
          v-for="regimen in regimens.items"
          :key="regimen.id"
          class="row row-group"
        >
          <div class="col-sm-9 pl-md-5">
            <h6>{{ regimen.name }}</h6>
            <p class="mb-2">
              {{ regimen.products.length }} Products | Created by {{ regimen.practice.name }}
            </p>
            <span
              v-for="tag in regimen.tags"
              :key="tag"
              class="badge badge-pill badge-primary mb-2 mr-2"
            >
              {{ getTagName(tag).value }}</span>
            <p v-if="regimen.description">
              {{ regimen.description }}
            </p>
            <div class="grid-images">
              <div
                v-for="product in regimen.products"
                :key="product.id"
                class="item-image"
              >
                <img
                  :src="product.image"
                  class="product"
                >
                <p v-html="product.title" />
                <span>
                  {{ product.vendor }}
                  <br>${{ product.price }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-3 action-box">
            <router-link
              v-if="showForPractice(regimen)"
              class="link_action"
              tag="a"
              :to="{
                name: 'RegimenEdit',
                params: {
                  id: regimen.id
                }
              }"
            >
              View
            </router-link>
          </div>
        </div>
      </div>
      <Pagination
        :total-rows="regimens.total_items"
        :total-pages="regimens.total_pages"
        :current-page="currentPage"
        :hide-per-page="true"
        :per-page="20"
        @change-page="changePage"
      />
      <!-- /. Table Regimens Information  -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
  body .preloader {
    position: static;
    background: transparent;
    float: left;
    padding-top: 0;
    i {
      width: 20px;
      height: 20px;
      border-width: 2px;
    }
  }
</style>

<script>
import { mapGetters } from 'vuex';
import { encodeQueryData } from '@/helper';
import { GET_PRESET_REGIMENS } from '@/store/regimen/actions.types';
import PresetRegimenFilters from './PresetReginemFilters';
import Pagination from '../partials/Paginations';
import { tags } from '@/store/regimen/initialObject';

// @vuese
// @group Preset Regimen
export default {
  name: 'PresetRegimen',
  components: {
    PresetRegimenFilters,
    Pagination
  },
  data() {
    return {
      sending: false,
      filters: {
        regimen_type: 'preset'
      },
      currentPage: 1,
      regimenTags: tags,
    };
  },
  computed: {
    ...mapGetters([
      'regimens',
      'practice'
    ])
  },
  beforeMount() {
    if (this.isRole('super_admin')) {
      this.filters['creators'] = 'superadmin';
    }
    this.loadPresets();
  },
  methods: {
    // @vuese
    // Used to filter by tags and load presets
    // @arg Tags
    filterTags(tags) {
      this.currentPage = 1;
      if (tags) this.filters.tags = tags;
      else delete this.filters.tags;

      this.loadPresets();
    },
    // @vuese
    // Used to filter by creators and load presets
    // @arg Creators
    filterCreators(creators) {
      this.currentPage = 1;
      if (creators) this.filters.creators = creators;
      else delete this.filters.creators;

      this.loadPresets();
    },
    // @vuese
    // Used to reset reset filters and load presets
    resetAll() {
      this.currentPage = 1;
      this.filters = {
        regimen_type: 'preset'
      };
      this.loadPresets();
    },
    // @vuese
    // Used to chage page store it to local state and load presets
    changePage(value) {
      this.scrollToTop()
      this.currentPage = value;
      this.loadPresets();
    },
    // @vuese
    // Used to prepare filter object
    buildRegimensParams() {
      return {
        filters: this.filters,
        page: this.currentPage,
      };
    },
    // @vuese
    // Used to load presets and store it to local store
    loadPresets() {
      if (this.sending) return;
      this.sending = true;

      this.$store.dispatch(GET_PRESET_REGIMENS, encodeQueryData(this.buildRegimensParams()))
        .finally(() => {
          this.sending = false;
        });
    },
    // @vuese
    // Used to get tag name
    // @arg tag key
    getTagName(key) {
      return this.regimenTags.find(tag => tag.name == key);
    },
    // @vuese
    // Used to check if we need to
    // @arg Regimen object
    showForPractice(regimen) {
      return this.isRole('super_admin') ? true : regimen.practice.id === this.practice.id;
    },

    scrollToTop() {
      this.$nextTick(() => {
        window.scrollTo({ top: 0, behavior: 'instant' });
      });
    }
  }
};
</script>