var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.showAsideLinks ? 'col-12 col-xl-9' : 'col-12'},[_c('h4',[_vm._v("\n    Recently Added Clients  \n    "),_c('router-link',{staticClass:"action-top float-sm-right",attrs:{"to":{
        name: 'Patients'
      },"tag":"a"}},[_vm._v("\n      see all\n    ")])],1),_vm._v(" "),_c('div',{staticClass:"content-table"},[(_vm.patients && _vm.patients.items.length)?_c('table',{staticClass:"table table-box table-striped"},[_vm._m(0),_vm._v(" "),_c('tbody',_vm._l((_vm.patients.items),function(patient){return _c('tr',{key:patient.id},[_c('td',[_vm._v(_vm._s(patient.name))]),_vm._v(" "),_c('td',[(patient.physician)?_c('span',[_vm._v(_vm._s(patient.physician.name))]):_vm._e()]),_vm._v(" "),_c('td',[_c('span',{staticClass:"status_field"},[_vm._v(_vm._s(patient.status))])]),_vm._v(" "),_c('td',[_c('span',[_vm._v(_vm._s(patient.date_added))])]),_vm._v(" "),_c('td',[(patient.status == 'completed')?_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                name: 'PatientView',
                params: {
                  id: patient.id
                }
              },"tag":"a","href":""}},[_vm._v("\n              View\n            ")]):_vm._e(),_vm._v(" "),(patient.status == 'pending')?_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                name: 'PatientCreateRegimen',
                params: {
                  id: patient.id
                }
              },"href":""}},[_vm._v("\n              Continue\n            ")]):_vm._e()],1)])}),0)]):_c('div',{staticClass:"wrap-table border-table"},[_c('div',{staticClass:"text-center"},[_vm._v("\n        No clients have been added\n      ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_vm._v(" "),_c('th',[_vm._v("Skincare Pro")]),_vm._v(" "),_c('th',[_vm._v("Status")]),_vm._v(" "),_c('th',[_vm._v("Added")]),_vm._v(" "),_c('th')])])
}]

export { render, staticRenderFns }