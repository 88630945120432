<template>
  <div class="bg-login">
    <div class="container">
      <Notifications />
      <div class="box-login">
        <ValidationObserver 
          ref="observer" 
          v-slot="{ invalid }" 
          class="login-form validate-form"
          tag="form" 
          @submit.prevent="forgotPass"
        >
          <div class="logo-img">
            <img 
              :src="logo" 
              alt="Logo"
            >
          </div>
          <h2 class="text-center mb-3">
            Forgot Password
          </h2>
          <p class="pb-3">
            A password reset link will be sent to your email.
          </p>

          <validation-provider 
            v-slot="{ errors }" 
            name="Email" 
            rules="email|required"
          >
            <div 
              class="box-input validate-input mb-5" 
              data-validate="Please enter a valid email address"
            >
              <label>Email address</label>
              <input 
                v-model="email" 
                placeholder="Email" 
                type="text" 
                :class="errors.length ? 'error' : ''"
                name="email"
              >
              <p class="validation error mt-2">
                {{ errors[0] }}
              </p>
            </div>
          </validation-provider>
          
          <button 
            href="#" 
            class="btn btn-primary"
          >
            Reset Password
          </button>
          <router-link 
            class="link-logging" 
            :to="{
              name: 'Login'
            }" 
            tag="a"
          >
            back to login
          </router-link>
        </ValidationObserver>
      </div>
    </div>
    <ContactUs />
  </div>
</template>

<script>
import logo from '../../../images/logo-primary.png';
import ContactUs from '../components/ContactUs';

import { ValidationProvider, ValidationObserver, extend  } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { FORGOT_PASSWORD } from '../../store/login/actions.types';

// Install rules
extend('required', required);

email['message'] = 'Please enter a valid email address';
extend('email', email);

// @group Login
export default {
  name: 'ForgotPassword',
  components: {
    ContactUs,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      logo: logo,
      email: ''
    };
  },
  methods: {
    // @vuese
    /// Used to validate form and send forgot email to user
    async forgotPass() {
      const { email } = this;
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        try {
          const resp = await this.$store.dispatch(FORGOT_PASSWORD, {
            user: {
              email
            }
          });

          if (resp.status === 201 || resp.status === 200) {
            this.flashSuccess('A password reset link has been sent to your email. The email may take up to 5 minutes to arrive.');
          }
        } catch (error) {
          if (error.response && error.response.data.errors) {
            const errors = error.response.data.errors;
            const errorMessages = [];

            // Loop through each field and its associated errors
            for (const field in errors) {
              if (errors.hasOwnProperty(field)) {
                errorMessages.push(`${this.titleize(field)} ${errors[field].join(', ')}`);
              }
            }

            this.flashError(`${errorMessages.join(' | ')}`);
          } else {
            this.flashError('An error occurred while processing your request. Please try again later.');
          }
        }
      }
    },

    titleize(label) {
      const formattedLabel = label.replace(/_/g, ' ');
      return formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1);
    }
  }
};
</script>