<template>
  <!-- Practice Team -->
  <div class="wrap-table border-table">
    <div class="row row-group row-group-loader-first">
      <div 
        v-if="sending"
        class="preloader"
      >
        <i />
      </div>
      <div class="col-sm-3">
        <span class="text-up">
          <strong>Practice Admin</strong>
        </span>
      </div>
      <div 
        v-if="!edit_admin && !reassign_admin && practice.admin" 
        class="col-sm-9 p-admin-info pr-0"
      >
        <div class="row-large">
          <div class="col-md-9">
            <span class=" d-block">{{ practice.admin.first_name }} {{ practice.admin.last_name }}</span>
            <a 
              class="btn-link pt-2" 
              :href="`mailto: ${practice.admin.email}`"
            >{{ practice.admin.email }}</a>
          </div>
          <div class="col-md-3 action-box">
            <a
              v-if="canUserEdit" 
              class="link_action"
              href="#"
              @click.prevent="editAdmin" 
            >Edit</a>
          </div>
        </div>
      </div>
      <ValidationObserver 
        v-if="edit_admin && !reassign_admin && practice.admin" 
        ref="form" 
        v-slot="{ invalid }" 
        tag="div"
        class="col-sm-9 p-admin-edit pr-0"
      >
        <validation-provider
          v-slot="{ errors }"
          name="Admin name"
          rules="required" 
          class="row-large"
        >
          <div class="col pr-0">
            <div class="form-group">
              <label for="admin_name">Admin Name</label>
              <input
                id="admin_name" 
                v-model="practice.admin.first_name"
                placeholder="Dr. Jean Grey"
                :class="errors.length ? 'error' : ''"
                type="text"
                name="name"
                :readonly="isSuperAdmin() || currentUser.id === practice.admin.id ? false : true"
              >
              <p class="validation error mt-2">
                {{ errors[0] }}
              </p>
            </div>
          </div>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="Admin email"
          rules="required" 
          class="row-large"
        >
          <div class="col pr-0">
            <div class="form-group">
              <div class="head-tooltip">
                <label for="admin_email">Admin Email
                  <i
                    class="fas fa-question-circle fa-xs"  
                    @mouseover="hover('admin-email')" 
                    @mouseleave="hoverOff('admin-email')"
                  />
                </label>
                <div 
                  data-tooltip-type="admin-email" 
                  class="tooltip-black"
                >
                  The practice admin’s email address is never shared with clients.
                </div>
              </div>
              <input 
                id="admin_email" 
                v-model="practice.admin.email"
                type="email" 
                :class="errors.length ? 'error' : ''"
                placeholder="jeangrey@practice.com"
                name="mail"
              >
              <p class="validation error mt-2">
                {{ errors[0] }}
              </p>
            </div>
          </div>          
        </validation-provider>
        <div class="row-large">
          <div class="col-xl-5 mb-xl-0 mb-2">
            <a 
              href="#"  
              class="new-p-admin"
              @click.prevent="reassign_admin = true"
            >
              <small>Assign New Practice Admin &#8250;</small>
            </a>
          </div>
          <div class="col-xl-7 pr-0">
            <div class="text-right">
              <a 
                href="#" 
                class="btn btn-bordered right-space"
                data-action="cancel"
                @click.prevent="cancel_edit"
              >Cancel</a>
              <a 
                href="#" 
                class="btn btn-primary autowidth" 
                data-action="save"
                @click.prevent="update"
              >Save</a>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <div 
        v-if="reassign_admin" 
        class="col-sm-9 p-admin-new pr-0"
      >
        <div class="row-large">
          <div class="col pr-0">
            <p>There can only be one practice admin at a time. Once a new practice admin is assigned, you will no longer have the ability to see sales reports or add/edit practice information and team members</p>
            <p>Please select a new practice admin from an existing team member.</p>
            <p class="text-up mb-2">
              Assign new practice admin
            </p>
            <p>
              <select v-model="newAdminId">
                <option 
                  disabled 
                  value="" 
                  selected
                >
                  Select from practice team members
                </option>
                <option 
                  v-for="phys in practice.physicians" 
                  :key="phys.id" 
                  :value="phys.id"
                >
                  {{ phys.first_name }} {{ phys.last_name }}
                </option>
              </select>
            </p>
            <p 
              v-if="!newAdminSelected" 
              class="error validation"
            >
              Please, select new practice admin
            </p>
          </div>
        </div>
        <div class="row-large">
          <div class="col-xl-5 mb-xl-0 mb-2">
            <a 
              href="#" 
              class="back-new-admin"
              @click.prevent="reassign_admin = false" 
            ><small>&#8249; Back</small></a>
          </div>
          <div class="col-xl-7 pr-0">
            <div class="text-right">
              <a 
                href="#" 
                class="btn btn-bordered right-space"
                @click.prevent="reassignCancel" 
              >Cancel</a>
              <a 
                href="#" 
                class="btn btn-primary autowidth"
                @click.prevent="reassignAdmin" 
              >Save</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <draggable
      class="top-picks-draggable-wrapper"
      handle=".top-picks-drag-btn-wrapper"
      v-model="practice.physicians" 
      @end="updateItemOrder"
    >
      <teamPhysician 
        v-for="(physician, index) in practice.physicians" 
        :key="'physican' + physician.id" 
        :physician="physician" 
        :index="index" 
        :can-edit="canUserEdit"
      />
    </draggable>

    <teamStaff 
      v-for="(staff, index) in practice.staff" 
      :key="staff.id" 
      :staff="staff" 
      :index="index" 
      :last="index == practice.staff.length - 1"
      :can-edit="canUserEdit" 
    />
      
    <div 
      v-if="getStaffCoutn() < 1" 
      class="row row-group staff-options last"
    >
      <div class="col-sm-3">
        <span class="text-up"><strong>Staff</strong></span>
      </div>
      <div class="col-sm-9 staff-info pl-0 pr-0">
        <div class="row-large">
          <div class="col-md-9">
            <span class="text-soft">
              You have no staff users
            </span>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :show-modal="showCancelModal"
      modal-id="before"
      class="modal fade dashboard-modal" 
      @hide-modal-window="showCancelModal = false"
    >
      <img 
        src="../../../../images/warning.png" 
        alt="Warning!" 
        class="warning-icon" 
      >
      <h4 class="modal-title text-center">
        Before you go ...
      </h4>
      <p class="text-center">
        You have unsaved changes. Are you sure you want to continue?
      </p>
      <div class="mb-3">
        <a 
          href="#" 
          class="btn btn-primary w-100"
          @click.prevent="hideModal" 
        >Continue Without Saving</a>
      </div>
      <div class>
        <a
          href="#"
          class="btn btn-bordered w-100"
          data-dismiss="modal"
          @click.prevent="showCancelModal = false"
        >Cancel</a>
      </div>
    </Modal>
    <div 
      v-if="showCancelModal" 
      class="modal-backdrop fade show"
    />
  </div>
  <!-- End Practice Team -->
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import Modal from '../partials/Modal';
import teamStaff from './teamStaff';
import teamPhysician from './teamPhysician';
import {
  REASSIGN_ADMIN, 
  UPDATE_ADMIN_INFO,
  SUPER_ADMIN_GET_PRACTICE,
  UPDATE_PHYSICIAN
} from '@/store/practice/actions.types';

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';

extend('required', {
  message: 'This field is required'
});

// Component to show practice admin, physican and staff
// @group Practice
export default {
  name: 'Team',
  components: {
    draggable,
    Modal,
    teamStaff,
    teamPhysician,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    // Property that show is current suer can edit allegran data
    canUserEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showCancelModal: false,
      edit_admin: false,
      reassign_admin: false,
      sending: false, 
      currentAdminInfo: {
        name: '',
        email: ''
      },
      newAdminId: '',
      newAdminSelected: true
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'practice'])
  },
  methods: {
    updateItemOrder() {
      this.practice.physicians.forEach((physician, index) => {
          const data = [],
            physicanPromises = [];
          data.push({
            data: {email: physician.email, position: index+1},
            id: physician.id
          });

          physicanPromises.push(this.$store.dispatch(UPDATE_PHYSICIAN, data[0]));

          Promise.all(physicanPromises)
            .then(() => {
              this.flashSuccess('Physician information updated');
            });
      });
    },

    isSuperAdmin() {
      return this.currentUser.roles.includes('super_admin');
    },

    getStaffCoutn(){
      if(this.practice.staff){
        return this.practice.staff.length;
      }else{
        return 0;
      }
    },
    // @vuese
    // Used to show tooltip
    // @arg HTML element data type
    hover(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.add('open');
    },
    // @vuese
    // Used to hide tooltip
    // @arg HTML element data type
    hoverOff(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.remove('open');
    },
    // @vuese
    // Used to update practice admin name and email
    async update(){
      const valid = await this.isValid();

      if (valid) {
        this.sending = true;

        const data = {
          admin: { 
            first_name: this.practice.admin.first_name,
            email: this.practice.admin.email
          }
        };

        this.$store.dispatch(UPDATE_ADMIN_INFO, data)
          .then(responses => {
            this.edit_admin = false;
            this.$store.dispatch(SUPER_ADMIN_GET_PRACTICE)
              .then(()=>{
                this.flashSuccess('Admin information updated');
              });
          })
          .catch(err => {
            const errors = err.data.errors;
            const data = err.config.data;
            const errEmail = data.get('admin[email]');
          
            if (errors.email) {
              if (this.practice.admin.email == errEmail) {
                this.practice.admin.emailErrors = errors.email;
              }
            }
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },

    // @vuese 
    // Show edit form and store value in case we do not want to save chenges, 
    // so we can return to what it was
    editAdmin(){
      this.edit_admin = true;
      this.currentAdminInfo.name = this.practice.admin.first_name;
      this.currentAdminInfo.email = this.practice.admin.email;
    },
    // @vuese
    // Used to hide modal and close form and restore old data
    hideModal(){
      this.practice.admin.first_name = this.currentAdminInfo.name;
      this.practice.admin.email = this.currentAdminInfo.email;
      this.showCancelModal = false;
      this.edit_admin = false;
    },
    // @vuese
    // Used to change practice admin
    reassignAdmin(){
      if(this.newAdminId == '') {
        this.newAdminSelected = false;
      } else {
        this.newAdminSelected = true;
        let data = { 
          new_admin_id: this.newAdminId
        };
        this.$store.dispatch(REASSIGN_ADMIN, data).then(
          () => {
            this.edit_admin = false;
            this.reassign_admin = false;
            this.$store.dispatch(SUPER_ADMIN_GET_PRACTICE).then(()=>{
              this.flashSuccess('Practice Admin has been successfully changed');
            });
          }
        );
      }
    },
    // @vuese
    // Used to cancel admin reassignation
    reassignCancel() {
      this.edit_admin = false;
      this.reassign_admin = false;
    },

    // @vuese
    // Used to hide edit form or if data was changed, 
    // then to ask do you want close without saving
    cancel_edit(){
      if(this.currentAdminInfo.name == this.practice.admin.first_name && this.currentAdminInfo.email == this.practice.admin.email){
        this.edit_admin = false;
      }else{
        this.showCancelModal = true;
      }
    },

    // @vuese
    // Used to check if form is valid
    isValid() {
      return this.$refs.form.validate();
    }
  }
};
</script>