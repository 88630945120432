import zipcodes from 'zipcodes';
import { isValidNumberForRegion } from 'libphonenumber-js';

// Used to check if value is valid URL
// @arg Value
export const isURL = (value) => {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return pattern.test(value);

  //return /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
};

// Used to capitalize string
// @arg String
export const capitalize = (value) => {
  value = value.toLowerCase();
  return value.charAt(0).toUpperCase() + value.substring(1);
};

// United States array
export const usStates = [
  {
    'name': 'Alabama',
    'abbreviation': 'AL'
  },
  {
    'name': 'Alaska',
    'abbreviation': 'AK'
  },
  {
    'name': 'Arizona',
    'abbreviation': 'AZ'
  },
  {
    'name': 'Arkansas',
    'abbreviation': 'AR'
  },
  {
    'name': 'California',
    'abbreviation': 'CA'
  },
  {
    'name': 'Colorado',
    'abbreviation': 'CO'
  },
  {
    'name': 'Connecticut',
    'abbreviation': 'CT'
  },
  {
    'name': 'Delaware',
    'abbreviation': 'DE'
  },
  {
    'name': 'District Of Columbia',
    'abbreviation': 'DC'
  },
  {
    'name': 'Florida',
    'abbreviation': 'FL'
  },
  {
    'name': 'Georgia',
    'abbreviation': 'GA'
  },
  {
    'name': 'Hawaii',
    'abbreviation': 'HI'
  },
  {
    'name': 'Idaho',
    'abbreviation': 'ID'
  },
  {
    'name': 'Illinois',
    'abbreviation': 'IL'
  },
  {
    'name': 'Indiana',
    'abbreviation': 'IN'
  },
  {
    'name': 'Iowa',
    'abbreviation': 'IA'
  },
  {
    'name': 'Kansas',
    'abbreviation': 'KS'
  },
  {
    'name': 'Kentucky',
    'abbreviation': 'KY'
  },
  {
    'name': 'Louisiana',
    'abbreviation': 'LA'
  },
  {
    'name': 'Maine',
    'abbreviation': 'ME'
  },
  {
    'name': 'Maryland',
    'abbreviation': 'MD'
  },
  {
    'name': 'Massachusetts',
    'abbreviation': 'MA'
  },
  {
    'name': 'Michigan',
    'abbreviation': 'MI'
  },
  {
    'name': 'Minnesota',
    'abbreviation': 'MN'
  },
  {
    'name': 'Mississippi',
    'abbreviation': 'MS'
  },
  {
    'name': 'Missouri',
    'abbreviation': 'MO'
  },
  {
    'name': 'Montana',
    'abbreviation': 'MT'
  },
  {
    'name': 'Nebraska',
    'abbreviation': 'NE'
  },
  {
    'name': 'Nevada',
    'abbreviation': 'NV'
  },
  {
    'name': 'New Hampshire',
    'abbreviation': 'NH'
  },
  {
    'name': 'New Jersey',
    'abbreviation': 'NJ'
  },
  {
    'name': 'New Mexico',
    'abbreviation': 'NM'
  },
  {
    'name': 'New York',
    'abbreviation': 'NY'
  },
  {
    'name': 'North Carolina',
    'abbreviation': 'NC'
  },
  {
    'name': 'North Dakota',
    'abbreviation': 'ND'
  },
  {
    'name': 'Ohio',
    'abbreviation': 'OH'
  },
  {
    'name': 'Oklahoma',
    'abbreviation': 'OK'
  },
  {
    'name': 'Oregon',
    'abbreviation': 'OR'
  },
  {
    'name': 'Pennsylvania',
    'abbreviation': 'PA'
  },
  {
    'name': 'Rhode Island',
    'abbreviation': 'RI'
  },
  {
    'name': 'South Carolina',
    'abbreviation': 'SC'
  },
  {
    'name': 'South Dakota',
    'abbreviation': 'SD'
  },
  {
    'name': 'Tennessee',
    'abbreviation': 'TN'
  },
  {
    'name': 'Texas',
    'abbreviation': 'TX'
  },
  {
    'name': 'Utah',
    'abbreviation': 'UT'
  },
  {
    'name': 'Vermont',
    'abbreviation': 'VT'
  },
  {
    'name': 'Virginia',
    'abbreviation': 'VA'
  },
  {
    'name': 'Washington',
    'abbreviation': 'WA'
  },
  {
    'name': 'West Virginia',
    'abbreviation': 'WV'
  },
  {
    'name': 'Wisconsin',
    'abbreviation': 'WI'
  },
  {
    'name': 'Wyoming',
    'abbreviation': 'WY'
  }
];

// Used to check if zip code valid for state
// @arg Zip code
// @arg State code
export const isZipValidForState = (zipCode, stateValue) => {
  const zipLookup = zipcodes.lookup(zipCode);
  const stateCode = stateValue.length == 2 ? stateValue : usStates.find(
    stateItem => stateItem.name === stateValue
  ).abbreviation

  return zipLookup ? zipLookup.state == stateCode : false;
};

// Used to coppy default address to delivery address
// @arg Default address
// @arg Delivery address
export const copyDefaultToDelivery = (defaultAddress, deliveryAddress) => {
  const newAddress = {...defaultAddress};
  newAddress.type = deliveryAddress.type;
  newAddress.note = deliveryAddress.note;
  if (deliveryAddress.action)
    newAddress.action = deliveryAddress.action;
  return newAddress;
};


// Used to convert object to get string
// @arg Query object
export const encodeQueryData = (data) => {
  const ret = [];
  for (let d in data) {
    if (typeof data[d] === 'object') {
      Object.keys(data[d]).forEach(key => {
        if (Array.isArray(data[d][key])) {
          data[d][key].forEach(val => {
            ret.push(`${d}[${key}][]` + '=' + val);
          });
        } else ret.push(`${d}[${key}]` + '=' + data[d][key]);
      });
    } else {
      ret.push(d + '=' + encodeURIComponent(data[d]));
    }
  }
  return ret.join('&');
};

// Used to get docuemnt offset position 
// @arg Node element
export const getDocumentOffsetPosition = (el) => {
  var position = {
    top: el.offsetTop,
    left: el.offsetLeft
  };
  if (el.offsetParent) {
    var parentPosition = getDocumentOffsetPosition(el.offsetParent);
    position.top += parentPosition.top;
    position.left += parentPosition.left;
  }
  return position;
};

// Used to format shopify GraphQl meta object
// @arg GraphQl edges
export const formatMetaModel = (edges) => {
  const data = [];
  edges.forEach(el => {
    if(el){
      if(el.node){
        data.push({...el.node});
      }else{
        data.push({...el});
      }
    }
  });
  return data;
};


// Used to find meta object in meta array
// @arg Meta array
// @arg Namespace
// @arg Key
export const findMeta = (metaArr, namespace, key) => {
  return metaArr.find(meta => {
    return meta.namespace == namespace && meta.key == key;
  });
};

// @vuese
// Used to get id from shopify graph ID
export const getShopifyId = (graphID) => {
  try {
    return atob(graphID).match(/\d+/g)[0];
  } catch (e) {
    console.warn(e);
    return graphID.match(/\d+/g)[0];
  }
};

// Used to format money
// @arg Money value
export const formatMoney = (amount) => {
  try {
    return Number(amount).toFixed(2);
  } catch(e) {
    return amount;
  }
};

export const formatStorefrontMoney = (amount) => {
  try {
    return Number(amount).toFixed(2);
  } catch(e) {
    return amount;
  }
};

// Used to format Shopify product and return product for our system
// @arg Product
// @arg Variant
// @arg Meta
export const formatProduct = (product, variant, meta = {}) => {
  return {
    'shopify_product_id': getShopifyId(product.id),
    'shopify_product_graph_id': product.id,
    'handle': product.handle,
    'variant_id': getShopifyId(variant.id),
    'variant_graph_id': variant.id,
    'discount_variant_id': meta.discount_variant_id ? meta.discount_variant_id : null,
    'usage_instruction': meta.usage_instruction ? meta.usage_instruction : null,
    'recomendation_type': meta.recomendation_type ? meta.recomendation_type : null,
    'time_of_use': meta.time_of_use ? meta.time_of_use : null,
    'frequency': meta.frequency ? meta.frequency : null,
    'image': product.images.length ? product.images[0].src : null,
    'sku': variant.sku,
    'title': product.title,
    'vendor': product.vendor,
    'price': variant.price.amount,
    'variant_title': variant.title,
    'sellingPlans': product.sellingPlans || []
  };
};

// Used to format Shopify product and return product for our top pick
// @arg Product
export const formatTopPick = (product) => {
  return {
    'shopify_product_id': getShopifyId(product.id),
    'body_html': product.descriptionHtml,
    'handle': product.handle,
    'image': product.images.length ? product.images[0].src : null,
    'images': product.images,
    'options': product.options,
    'product_type': product.productType,
    'tags': product.tags,
    'title': product.title,
    'vendor': product.title,
    'variants': product.variants,
  };
};

// @vuese
// Used to convert address object to string, if object have empty  props
// return word `None`
// @arg address object
export const bulidAddressStr = (data) => {
  const resp = [];
  if (!data) return 'None';
  if (data.first_name || data.last_name) resp.push(`${data.first_name || ''} ${data.last_name || ''}`.trim());
  if (data.address1) resp.push(data.address1);
  if (data.address2) resp.push(data.address2);
  if (data.city) resp.push(data.city);
  if (data.state || data.zip) resp.push(`${data.state || ''} ${data.zip || ''}`.trim());
  if (data.phone) resp.push(data.phone);
  if(resp.length < 2){
    return 'None';
  }else{
    return resp.join(', ');
  }
};

// Used to check if user have role
// @arg User
// @arg Role
export const isRole = (user, role) => {
  return user.roles.includes(role);
};

export const checkPracticeCompetion = (practice, user, $router, next = null) => {
  let route;
  if (isRole(user, 'practice_admin') && !isRole(user, 'super_admin') && !isRole(user, 'group_practice_admin') && !isRole(user, 'group_admin')) {
    if (practice.registration_completed) {
      route = {
        name: 'userdashboard'
      };
    } else if (practice?.staff?.length) {
      route = {
        name: 'CreateAccountS6',
        params: {
          checkPractice: false,
        }
      };
    } else if (practice?.physicians?.length > 1) {
      route = {
        name: 'CreateAccountS5',
        params: {
          checkPractice: false,
        }
      };
    } else if (practice?.default_address?.address1 || practice?.delivery_address?.address1) {
      route = {
        name: 'CreateAccountS4',
        params: {
          checkPractice: false,
        }
      };
    } else if (practice.email) {
      route = {
        name: 'CreateAccountS3',
        params: {
          checkPractice: false,
        }
      };
    } else {
      route = {
        name: 'CreateAccountS2',
        params: {
          checkPractice: false,
        }
      };
    }
  } else {
    route = {name: 'userdashboard'};
  }
  if (next) next(route);
  else $router.push(route);
};

// @vuese
// Used to calculate delta
// @arg Current perriod value
// @arg / Previous period value
export const calcDelta = (a, b) => {
  let output = 0;
  if (a == b) {
    output = 0;
  } else if (a == 0) {
    output = -100;
  } else if (b == 0) {
    output = 100;
  } else if (a > b) {
    output = Math.abs((b/a-1) * 100);
  } else {
    // output = ((Math.abs(a - b) * a) / ((a - b) * b)) * 100;
    output = (a/b-1) * 100;
  }
  return Number.isInteger(output) ? output : output.toFixed(2);
};

// @vuese
// Used to get report minus or plus class, based on delta value
// @arg Delta value
export const getReportDeltaClass = (val) => {
  return val == null || val.toString().indexOf('-') === -1 ? 'pplus' : 'pminus';
};

// @vuese
// Finally Polyfil for old brouwsers to support it
export const promiseFinallyPolyfill = () => {
  if ('Promise' in window === false) return;
  // Get a handle on the global object
  var local;
  if (typeof global !== 'undefined') local = global;
  else if (typeof window !== 'undefined' && window.document) local = window;
  else local = self;

  // It's replaced unconditionally to preserve the expected behavior
  // in programs even if there's ever a native finally.

  if (new Promise(function(){}).finally !== undefined) {
    return;
  }

  local.Promise.prototype['finally'] = function finallyPolyfill(callback) {
    var constructor = this.constructor;

    return this.then(function(value) {
      return constructor.resolve(callback()).then(function() {
        return value;
      });
    }, function(reason) {
      return constructor.resolve(callback()).then(function() {
        throw reason;
      });
    });
  };
};

// @vuese
// Used to remove HTML from string
// @arg String
export const stripHTML = (string) => {
  return string.replace(/(<([^>]+)>)/ig, '');
};

// @vuese
// Used to check if phone valid for US
export const validPhone = (number) => {
  // @arg The argument is a integet number
  return isValidNumberForRegion(number, 'US');
};

// @vuese
// Used to remove style attr from HTML
// @arg HTMl string
export const stripStyle = (str) => {
  return str.replace(/(style=".*?")/gui, '');
};

// @vuese
// Used to return recommentation type pill v alue
export const renderRecommendationType = (val) => {
  return val == 'Also Recommended' ? 'Recommended' : val.split(' ')[0];
};

// @vuese
// Used to add format function to Date object
export const addFromatToDate = () => {
  var D = 'Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday'.split(','),
    M = 'January,February,March,April,May,June,July,August,September,October,November,December'.split(',');
  Date.prototype.format = function(format) {
    var me = this;
    return format.replace(/a|A|Z|S(SS)?|ss?|mm?|HH?|hh?|D{1,4}|M{1,4}|YY(YY)?|'([^']|'')*'/g, function(str) {
      var c1 = str.charAt(0),
        ret = str.charAt(0) == '\''
          ? (c1=0) || str.slice(1, -1).replace(/''/g, '\'')
          : str == 'a'
            ? (me.getHours() < 12 ? 'am' : 'pm')
            : str == 'A'
              ? (me.getHours() < 12 ? 'AM' : 'PM')
              : str == 'Z'
                ? (('+' + -me.getTimezoneOffset() / 60).replace(/^\D?(\D)/, '$1').replace(/^(.)(.)$/, '$10$2') + '00')
                : c1 == 'S'
                  ? me.getMilliseconds()
                  : c1 == 's'
                    ? me.getSeconds()
                    : c1 == 'H'
                      ? me.getHours()
                      : c1 == 'h'
                        ? (me.getHours() % 12) || 12
                        : (c1 == 'D' && str.length > 2)
                          ? D[me.getDay()].slice(0, str.length > 3 ? 9 : 3)
                          : c1 == 'D'
                            ? me.getDate()
                            : (c1 == 'M' && str.length > 2)
                              ? M[me.getMonth()].slice(0, str.length > 3 ? 9 : 3)
                              : c1 == 'm'
                                ? me.getMinutes()
                                : c1 == 'M'
                                  ? me.getMonth() + 1
                                  : ('' + me.getFullYear()).slice(-str.length);
      return c1 && str.length < 4 && ('' + ret).length < str.length
        ? ('00' + ret).slice(-str.length)
        : ret;
    });
  };
};