<template>
  <div class="content-wrap">
    <h2>Orders</h2>
    <ul class="breadcrumb-page mb-3">
      <li
        class="crumb"
      >
        All Orders
      </li>
    </ul>
    <orders-filter
      v-model="filteringParams"
      @change="filterOrders"
    />

    <hr>

    <div class="tbl-toolbar text-right">
      <div 
        v-show="sending" 
        class="preloader"
      >
        <i />
      </div>
      <a
        href="#"
        @click="downloadOrdersCsv"
      >
        <img
          v-if="!loadCSV"
          :src="csvSrc"
          alt="export"
        >
        <div
          v-show="loadCSV"
          class="preloader"
        >
          <i />
        </div>
        Export .CSV
      </a>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="content-table">
          <table class="table orders-table table-box table-striped">
            <thead>
              <tr>
                <th
                  :class="getSortClass('created_at')"
                  @click="setSorting('created_at')"
                >
                  Order Placed
                </th>
                <th
                  :class="getSortClass('number')"
                  @click="setSorting('number')"
                >
                  Order Number
                </th>
                <th
                  :class="getSortClass('patient_name')"
                  @click="setSorting('patient_name')"
                >
                  Client
                </th>
                <th
                  :class="getSortClass('physician_name')"
                  @click="setSorting('physician_name')"
                >
                  Skincare Pro
                </th>
                <th
                  v-if="isRole('super_admin')"
                >
                  Practice
                </th>
                <th
                  :class="getSortClass('total_price')"
                  @click="setSorting('total_price')"
                >
                  Total
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr v-if="orders.length < 1">
                <td colspan="8" class="text-center pt-3"> No results found. </td>
              </tr>
              <tr
                v-for="(order, index) in orders"
                :key="index"
              >
                <td>
                  <span v-if="order.shopify">
                    {{ order.shopify.created_at }}
                  </span>
                </td>
                <td>
                  <router-link
                    v-if="order.status === 'completed' && order.shopify"
                    class="link_action"
                    :to="{
                      name: 'ShowOrder',
                      params: {
                        id: order.id
                      }
                    }"
                  >
                    #{{ order.shopify.order_number }}
                  </router-link>
                  <span v-else>-</span>
                </td>
                <td>
                  <span v-if="order.patient">
                    {{ order.patient.name }}
                  </span>
                </td>
                <td>
                  <span v-if="order.physician">
                    {{ order.physician.name }}
                  </span>
                </td>
                <td
                  v-if="isRole('super_admin')"
                >
                  {{ getPracticeName(order) }}
                </td>
                <td>
                  <span>{{ order.total_price }}</span>
                </td>
                <td align="right">
                  <router-link
                    class="link_action"
                    :to="{
                      name: 'ShowOrder',
                      params: {
                        id: order.id
                      }
                    }"
                  > 
                    {{ order.status === 'completed' && order.shopify !== null ? 'View' : 
                      order.status == 'failed' ? 'Failed' : 'Confirmed' }}
                  </router-link>

                  <!-- <div
                    class="head-tooltip"
                  >
                    <span>
                      Confirmed
                    </span>
                    <div 
                      :data-tooltip-type="`order-view-${order.id}`" 
                      class="tooltip-black order-tooltip"
                    >
                      Order details will be available once the manufacturer receives the order. Any additional orders placed today for this client will show under the same order number.
                    </div>
                  </div> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Table orders -->
    <pagination
      :total-rows="pagination.totalRows"
      :total-pages="pagination.totalPages"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      :hide-per-page="false"
      @change-page="changePage"
      @change-per-page="changePerPage"
    />
  </div>
</template>

<style lang="scss" scoped>
  body .preloader {
    position: static;
    background: transparent;
    float: left;
    padding-top: 0;
    i {
      width: 20px;
      height: 20px;
      border-width: 2px;
    }
  }
  .tbl-toolbar .preloader {
    display: inline-flex;
    position: static;
    background: transparent;
    vertical-align: middle;
    padding-top: 0;
    float: none;
    margin-right: 3px;
    i {
      width: 15px;
      height: 15px;
      border-width: 2px;
    }
  }
</style>

<script>
import csvSrc from '@images/export-icon.png';
import { encodeQueryData } from '@/helper';
import fileDownloader from '@/helper/fileDownloader';
import { OrdersService } from '@/services/api.service';
import Pagination from '@/views/dashboard/partials/Paginations';
import OrdersFilter from '@/views/dashboard/partials/OrdersFilter';

// @group Orders
export default {
  name: 'ListOrders',
  components: {
    OrdersFilter,
    Pagination
  },

  props: {
    practiceId: {
      type: [Number, String],
      default: '',
    },
    practices: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      sending: false,
      csvSrc,
      loadCSV: false,
      pagination: {
        totalPages: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 20
      },
      filteringParams: {
        filters: {}
      },
      sortingParams: {
        orders: {
          created_at: 'desc'
        }
      },
      orders: []
    };
  },

  watch: {
    sortingParams() {
      this.fetchOrders();
    }
  },

  created() {
    // this.fetchOrders();
  },

  methods: {
    // @vuese
    // Used to create temp div pass download data to it and click on it
    // @arg base64File
    // @arg name of attribute
    fileDownloader,

    // @vuese
    // Used to build query object
    buildFilterObject() {
      return {
        ...this.filteringParams,
        ...this.sortingParams,
        page: this.pagination.currentPage,
        per: this.pagination.perPage
      };
    },

    // @vuese
    // Convert filter object to query string
    orderParams() {
      return encodeQueryData(
        this.buildFilterObject()
      );
    },

    // @vuese
    // Used to to change page, fetch new orders 
    changePage(currentPage) {
      this.scrollToTop();
      this.pagination.currentPage = currentPage;
      this.fetchOrders();
    },


    // @vuese
    // Used to change per page in pagination and fetch orders
    // @arg Per page value `Number`
    changePerPage(perPage) {
      this.scrollToTop();
      this.pagination.perPage = perPage;
      this.fetchOrders();
    },

    // @vuese
    // Used to load new orders after some data changes in folter component
    filterOrders() {
      this.pagination.currentPage = 1;
      this.fetchOrders();
    },

    // @vuese
    // Used to fetch orders from DB
    fetchOrders() {
      if (this.sending) return;

      this.sending = true;
      return OrdersService.list(
        this.orderParams()
      ).then(this.setOrders)
        .finally(() => this.sending = false);
    },

    // @vuese
    // Used to download load CSV
    downloadOrdersCsv() {
      if (this.loadCSV) return;
      this.loadCSV = true;
      return OrdersService.listCsv(
        this.orderParams()
      ).then((response) => {
        this.fileDownloader(response.data, this.buildFilename());
      })
        .finally(() => {
          this.loadCSV = false;
        });
    },

    // @vuese
    // Used to build filename for CSV
    buildFilename() {
      return `orders-${(new Date).toDateString()}.csv`;
    },

    // @vuese
    // Used to store data to local state
    // @arg Server response
    setOrders({ data }) {
      this.orders = data.orders.items;
      this.pagination.totalPages = data.orders.total_pages;
      this.pagination.totalRows = data.orders.total_items;
    },

    // @vuese
    // Used to set by what we sort orders
    // @arg By what we sort
    // @arg `/` Direction `DESC` or `ACS`
    setSorting(by, direction= 'desc') {
      if (this.sortingParams['orders'][by] && this.sortingParams['orders'][by] == 'desc')
        direction = 'asc';

      this.sortingParams['orders'] = { [`${by}`]: direction };
      this.fetchOrders();
    },

    // @vuese
    // Used to show tooltip
    // @arg HTML element data type
    hover(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.add('open');
    },

    // @vuese
    // Used to hide tooltip
    // @arg HTML element data type
    hoverOff(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.remove('open');
    },

    // @vuese
    // Used to get class for sort elements
    getSortClass(by) {
      return this.sortingParams['orders'][by] ? `sort-${this.sortingParams['orders'][by]}` : 'sortable';
    },

    // @vuese
    // Used to get practice name for super admin user
    getPracticeName(order) {
      let practiceName = '';
      
      if (order.practice && order.practice.name) {
        practiceName = order.practice.name;
      } else if (Array.isArray(this.practices) && this.practiceId) {
        const practice = this.practices.find(p => p.id == this.practiceId);
        if (practice) practiceName = practice.name;
      }
      return practiceName;
    },

    scrollToTop() {
      this.$nextTick(() => {
        window.scrollTo({ top: 0, behavior: 'instant' });
      });
    }
  }

};
</script>
