<template>
  <div class="content-wrap">
    <h2>
      Dashboard
      <a
        v-if="!isRole('group_admin')"
        class="action-top btn btn-link float-sm-right pr-0"
        data-toggle="modal"
        data-target="#add_patients"
        @click="showAddPatient"
      >
        <i class="cicle-icon fas fa-plus" /> Add New Client
      </a>
    </h2>
    <div 
      :class="showAsideLinks() ? 'row' : 'row'"
    >
      <addedPatients
        :show-aside-links="showAsideLinks()"
      />
      <asideLinks 
        v-if="showAsideLinks()"
        :practices="practices"
      />
    </div>

    <hr
      v-if="isRolePresent() && !isRole('staff') && !isRole('super_admin') && !isRole('group_practice_admin')"
      class="mb-3"
    >
    <key-metrics 
      v-if="isRolePresent() && !isRole('staff') && !isRole('super_admin') && !isRole('group_practice_admin')"
      :practice-created-at="practice.created_at"
    />
    <!-- /. dbox metrics -->
    <hr class="my-sm-4">
    <h4>
      Recent Orders &nbsp;
      <router-link 
        :to="{
          name: 'Orders'
        }" 
        tag="a" 
        class="action-top float-sm-right"
      >
        see all
      </router-link>
    </h4>
    <recentOrders />
    <!-- /. Recent Orders -->

    <Modal 
      :show-modal="showAddModal" 
      modal-content-class="overflow-visible"
      modal-id="add_patients"
      @hide-modal-window="hideModal()"
    >
      <AddPatientModal 
        :show-modal="showAddModal"
        :allow-filter-by-practice="false"
        @add-patient="hideModal()"
        @hide-modal-window="hideModal()"
      />
    </Modal>
    <div 
      v-if="showAddModal" 
      class="modal-backdrop fade show" 
    />
  </div>
</template>

<style lang="scss" src="../../../../src/partial/_practice.scss"></style>

<script>
import { mapGetters } from 'vuex';
import ApiService from '@/services/api.service';

import addedPatients from './DashboardAddedPatients';
import asideLinks from './DashboardAsideLinks';
import recentOrders from './DashboardRecentOrders';
import keyMetrics from './DashboardKeyMetrics';
import Modal from '../partials/Modal';
import AddPatientModal from '../patients/AddPatientModal';

// @group Dashboard main
export default {
  name: 'DashboardMain',

  components: {
    addedPatients,
    asideLinks,
    recentOrders,
    keyMetrics,
    Modal,
    AddPatientModal
  },

  props: {
    // Array of practices loaded when super admin user loaded
    practices: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      showAddModal: false
    };
  },

  computed: {
    ...mapGetters([
      'currentUser', 
      'practice', 
      'address'
    ])
  },

  methods: {
    // @vuese
    // Used to check should we show aside link or not depend on if it is 
    // super admin or other type of user
    showAsideLinks() {
      return this.isRole('super_admin') ? ApiService.practiceId : true;
    },

    // @vuese 
    // Function to show add patient modal in parent component
    showAddPatient() {
      // Trigger to show add patient modal
      this.showModal();
    },

    // @vuese 
    // Used to show modal
    // @arg Argument to find witch modal should be shown
    showModal() {
      this.showAddModal = true;
    },

    // @vuese 
    // Used to hide modal
    // @arg Argument to find witch modal should be hidden
    hideModal() {
      this.showAddModal = false;
    },
  }
};
</script>