<template>
  <div class="front-wrapper">
    <Header :patient="patient" />
    <div class="frontend-main">
      <div class="container container-md-xs">
        <Notifications />
        <div class="steps-wrap">
          <div class="step current">
            <p>Create Regimen</p>
            <span class="step-marker" />
          </div>

          <!-- <div class="step">
            <router-link 
              class="hover-btn"
              :to="{
                name: 'PatientCreateInfo',
                params: {
                  id: patient.id
                }
              }"
              tag="p"
            >
              Client Information
            </router-link>
            <span class="step-marker" />
          </div> -->

          <div class="step">
            <p>Review</p>
            <span class="step-marker" />
          </div>
        </div>
      </div>
      <div class="container container-md-lg">
        <div class="row">
          <div class="col-md-7">
            <h2>Create Regimen</h2>
            <div 
              v-if="regimenBoxSelected"
              class="mt-n3 mb-4 pb-1 box-back"
            >
              <a 
                class="go-back hover-btn"
                @click.prevent="selectRegimenBox('')"
              >
                Back
              </a>
            </div>
          </div>
          <div class="col-md-5">
            <h4>Client's Regimen</h4>
            <p>Add products by selecting a preset regimen or building your own</p>
          </div>
        </div>

        <div class="row radio-actions">
          <div class="col-md-7">
            <div 
              v-if="!regimenBoxSelected"
              class="d-box with-lpad radio-selects"
            >
              <div class="input-radio">
                <input 
                  id="radio2" 
                  v-model="regimenBoxSelected"
                  type="radio" 
                  name="radio" 
                  :value="regimenBox.own"
                >
                <label 
                  for="radio2" 
                  class="radio-label hover-btn"
                >
                  <strong>Build Your Own Regimen</strong>
                </label>
                <p>
                  Search all products on RegimenPro<sup>®</sup> to create a custom regimen
                </p>
              </div>

              <div class="hr-with-text">
                <span>or</span>
              </div>

              <div class="input-radio">
                <input 
                  id="radio1" 
                  v-model="regimenBoxSelected"
                  name="radio" 
                  type="radio" 
                  :value="regimenBox.preset"
                >
                <label 
                  for="radio1" 
                  class="radio-label hover-btn"
                >
                  <strong>Select A Preset Regimen</strong>
                </label>
                <p>Choose from pre-established regimens</p>
              </div>
            </div>
            <!-- Radio actions -->
            <div id="groups-rad">
              <PresetRegimen 
                v-if="regimenBoxSelected == regimenBox.preset"
                :header="'Select A Preset Regimen'"
                :patient="patient"
                @add-products="presetAddProducts"
              />
              <BuildRegimen 
                v-if="regimenBoxSelected == regimenBox.own"
                :open-product-modal="openProductModal"
                :selected-products="selectedProducts"
                :patient="patient"
              />
            </div>
            <!-- hidden box -->
          </div>

          <div class="col-md-5">
            <SelectedProductList
              v-model="selectedProducts"
              :used-drag-handle="true"
              :helper-class="'sortable-box-wrapper'"
              :hide-sortable-ghost="false"
              :should-cancel-start="shouldCancelStartFunc"
              :loading="loading"
              :selected-products="selectedProducts"
              @create="createPatientRegimen"
              @sort-start="sortStart"
              @sort-end="sortEnd"
            />
          </div>
        </div>
        <!-- /. row-create-account -->
      </div>
    </div>
    <Modal 
      :show-modal="showModal" 
      :modal-type="'popup-lg'"
      modal-id="add_product"
      @hide-modal-window="showModal = false"
    >
      <ProductModal
        :key="showModal"
        :product-id="productId"
        :modal-action="modalAction"
        :selected-product="selectedProduct"
        @remove-product="removeProduct"
        @hide-modal-window="showModal=false"
        @product-selected="productSelected"
      />
    </Modal>

    <div 
      v-if="showModal" 
      class="modal-backdrop fade show"
    />
  </div>
</template>

<style>
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active, .list-leave-active {
    transition: all 1s;
  }
  .list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
  }
</style>

<script>
import Header from './Header';
import PresetRegimen from '../partials/PresetRegimen';
import BuildRegimen from '../partials/BuildRegimen';
import Modal from '../partials/Modal';
import ProductModal from '../partials/ProductModal';
import SelectedProductList from '@/views/components/SelectedProductList';
import { RegimenService } from '@/services/api.service';
import { formatMoney, renderRecommendationType } from '@/helper';

// @group Patinet
// Component to create or update patinet regiemn
export default {
  name: 'PatientCreateRegimen',
  components: {
    Header,
    PresetRegimen,
    BuildRegimen,
    Modal,
    ProductModal,
    SelectedProductList
  },
  props: {
    // @vuese
    // Patient Object
    patient: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      loading: false,
      showModal: false,
      regimenId: null,
      productId: null,
      selectedProduct: null,
      patientId: null,
      regimenBox: {
        preset: 'preset',
        own: 'own',
      },
      regimenBoxSelected: '',
      selectedProducts: [],
      modalAction: 'create',
    };
  },
  watch: {
    patient: {
      handler: 'fillData',
      immediate: true
    }
  },
  beforeMount(){
    window.scrollTo(0,0);
  },
  methods: {
    renderRecommendationType,

    // @vuese
    // Used to select witch box should be sown
    // Preset or Build Regimen
    selectRegimenBox(type) {
      this.regimenBoxSelected = type;
    },
    // @vuese
    // Used to update patient Regimen or redirect to patient page
    // if regimen is empty
    createPatientRegimen() {
      if (this.selectedProducts.length) {
        this.handleProducts(this.regimenId);
      } else {
        this.$router.push({name: 'PatientCreateReview', params: {id: this.patientId}});
      }
    },
    // @vuese
    // Used to go through all selected products in regimen
    // and call update or create product function
    // @arg Regimen ID
    handleProducts(regimenId) {
      this.loading = true;
      const productHandles = [];
      this.selectedProducts.forEach((product,index) => {
        product.position = index;
        if (product.id) productHandles.push(this.updateProduct(product, regimenId));
        else productHandles.push(this.creteProduct(product, regimenId));
      });
      Promise.all(productHandles)
        .then(resp => {
          this.patient.regimen.products = resp.map(productResp => {
            return productResp.data.product;
          });
          this.$router.push({name: 'PatientCreateReview', params: {id: this.patientId}});
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // @vuese
    // Used to add product to regimen
    // @arg product
    // @arg / Regimen ID
    creteProduct(product, regimenId) {
      return RegimenService.addProduct({
        product: product,
      }, regimenId, this.getRequestPracticeId());
    },
    // @vuese
    // Used to update product to regimen
    // @arg product
    // @arg / Regimen ID
    updateProduct(product, regimenId) {
      return RegimenService.updateProduct({
        product: product,
      }, regimenId, product.id, this.getRequestPracticeId());
    },
    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      return this.isRole('super_admin') ? `/${this.patient.practice.id}` : '';
    },
    // @vuese
    // Calc total price of all products
    calcSubtotal() {
      return this.selectedProducts.reduce((sum, product) => {
        return sum + (product.price ? parseFloat(product.price) : 0);
      }, 0);
    },
    // @vuese
    // Used format money
    // @arg Price value
    formatMoney (val) {
      return formatMoney(val);
    },
    // @vuese
    // Used to add product to selected products
    // @arg product
    productSelected(value) {
      this.showModal = false;
      const productIndex = this.selectedProducts.findIndex(product => {
        return product.shopify_product_graph_id == value.shopify_product_graph_id;
      });
      if (productIndex !== -1) {
        this.selectedProducts.splice(productIndex, 1, value);
      } else {
        const postion = this.selectedProducts.length ? this.selectedProducts[this.selectedProducts.length - 1].position + 1 : 0;
        value.position = postion;
        this.selectedProducts.push(value);
      }
    },
    // @vuese
    // Used to remove product from selected list
    // @arg Product object
    removeProduct(value) {
      this.showModal = false;
      const productIndex = this.selectedProducts.findIndex(product => {
        return product.shopify_product_graph_id == value.shopify_product_graph_id;
      });
      if (productIndex !== -1) {
        const localProductId = this.selectedProducts[productIndex].id;
        if (localProductId) {
          this.loading = true;
          RegimenService.deleteProduct(this.regimenId, localProductId)
            .then(() => {
              this.selectedProducts.splice(productIndex, 1);
              this.loading = false;
            });
        } else {
          this.selectedProducts.splice(productIndex, 1);
        }
      }
    },
    // @vuese
    // Used to open product modal in create mode
    // @arg Product ID
    openProductModal(productId) {
      this.productId = productId;
      this.selectedProduct = null;
      this.modalAction = 'create';
      this.showModal = true;
    },
    // @vuese
    // Used to open product modal in update mode
    // @arg Product ID
    // @arg / Product object
    editProductModal(productId, selectedProduct) {
      this.productId = productId;
      this.selectedProduct = selectedProduct;
      this.modalAction = 'update';
      this.showModal = true;
    },
    // @vuese
    // Used to add product from presets to selected products
    // @arg Array of products
    presetAddProducts(products) {
      products.forEach(product => {
        const matchedProduct = this.selectedProducts
          .findIndex(el => el.shopify_product_graph_id == product.shopify_product_graph_id);

        if (matchedProduct != -1) {
          /// Replace id
          product.id = this.selectedProducts[matchedProduct].id;
          product.position = this.selectedProducts[matchedProduct].position;
          this.selectedProducts.splice(matchedProduct, 1, product);
        } else {
          // Remove old product id
          delete product.id;
          const postion = this.selectedProducts.length ? this.selectedProducts[this.selectedProducts.length - 1].position + 1 : 0;
          product.position = postion;
          this.selectedProducts.push(product);
        }
      });
    },
    // @vuese
    // Used to fill local store date
    fillData() {
      const { regimen } = this.patient;
      this.patientId = this.$route.params.id;
      if (regimen) {
        this.regimenId = regimen.id;

        if (regimen.products.length !== 0) 
          this.selectedProducts = regimen.products;
      }
    },
    // @vuese
    // Used to add class to node when start dragging
    // @arg Node event
    sortStart(e){
      e.node.classList.add('dragging');
    },
    // @vuese
    // Used to remove class from nodes with class `dragging` when sort end
    sortEnd(){
      document.querySelector('.dragging').classList.remove('dragging');
    },
    // @vuese
    // Used to stop draging if it is not drag icon
    // @arg Node event
    shouldCancelStartFunc(e){
      if(e.target.hasAttribute('data-index')){
        let product = this.selectedProducts[e.target.getAttribute('data-index')];
        this.editProductModal(product.shopify_product_graph_id, product);
        return true;
      }else{
        if(e.target.classList.contains('icon') || e.target.parentNode.classList.contains('icon'))
          return false;
        else 
          return true;
      }
    }
  }
};
</script>