<template>
  <div 
    v-if="physician.role != 'practice_admin'" 
    class="row row-group row-group-loader"
  >
    <div 
      v-if="sending"
      class="preloader"
    >
      <i />
    </div>
    <div class="top-picks-drag-btn-wrapper">
      <button class="top-picks-drag-btn">
        <i class="fas fa-grip-vertical" />
      </button>
    </div>
    <div class="col-sm-2">
      <span class="text-up">
        <strong>Skincare Pro</strong>
      </span>
      <div 
        v-if="!physician.invitation_accepted" 
        class="popup-link"
      >
        <router-link 
          :to="{
            name: 'LoginFirstTime',
          }" 
          tag="a" 
          class="badge badge-pill badge-primary"
        >
          Invite Sent
        </router-link>
        <div class="popup-black">
          An email invite has been sent with a link to activate their account, IF your team member has
          difficulty finding their email, they can also click on "First time user?" on the RegimenMD login screen.
        </div>
      </div>
    </div>
    <div class="col-sm-1">
      <img 
          :src="physician.avatar.url || physician.avatarPreview" 
          class="team-m-img" 
        >
    </div>
    <div 
      v-if="!edit && !remove" 
      class="col-sm-9 p-physician-edition pr-0"
    >
      <div class="row-large">
        <div class="col-md-8">
          <span style="float: left;">
            {{ physician.first_name }} {{ physician.last_name }}
            <br>
            {{ physician.email }}
            <br>
            <span v-if="physician.allegran.shipto || physician.allegran.soldto">
              Allergan APP SOLDTO: {{ physician.allegran.soldto }}
              <br>
              Allergan APP SHIPTO: {{ physician.allegran.shipto }}
            </span>
            <span v-else>
              <span v-if="!practice.allegran.soldto && !practice.allegran.shipto">
                Allergan APP not connected
              </span>
              <span v-else>
                Allergan APP inherited from practice
              </span>
            </span>
          </span>
        </div>
        <div class="col-md-2 action-box">
          <a :href="physician.physician_top_picks"
             class="link_action"
             data-type="top_picks"
             target="_blank"
             @click.prevent="copingLink" 
          >Top picks</a>
        </div>
        <div 
          v-if="copyedTopPickLink" 
          class="reg_link timelink"
        >
          Copied to Clipboard
        </div>
        <div class="col-md-2 action-box">  
          <a 
            v-if="canEdit"
            href="#"  
            class="link_action"  
            @click.prevent="editPhysician" 
          >Edit</a>
        </div>
      </div>
    </div>
    <div 
      v-if="edit && !remove" 
      class="col-sm-9 p-physician-edit pr-0"
    >
      <div class="row-large">
        <ValidationObserver 
          ref="form" 
          v-slot="{ invalid }" 
          class="col pr-0 pl-0 " 
          tag="form"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="`Physician name ${index}`"
            rules="required"
          >
            <label :for="`name-${index}`">SKINCARE PRO NAME</label>
            <input
              :id="`name-${index}`"
              v-model="physician.first_name"
              placeholder="Dr. Jean Grey"
              type="text"
              :class="errors.length ? 'error' : ''"
              name="name"
              :readonly="isSuperAdmin() || currentUser.id === physician.id ? false : true"
            >
            <p class="validation error mt-2">
              {{ errors[0] }}
            </p>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            :name="`Physician email ${index}`"
            rules="required"
          >
            <div class="head-tooltip">
              <label :for="`mail-${index}`">
                SKINCARE PRO EMAIL
                <i
                  class="fas fa-question-circle fa-xs" 
                  @mouseover="hover(`physician-email-${index}`)" 
                  @mouseleave="hoverOff(`physician-email-${index}`)"
                />
              </label>
              <div 
                :data-tooltip-type="`physician-email-${index}`" 
                class="tooltip-black"
              >
                The practice admin’s email address is never shared with clients.
              </div>
            </div>
            <input
              :id="`mail-${index}`"
              v-model="physician.email"
              placeholder="jeangrey@practice.com"
              type="text"
              :class="errors.length || physician.emailErrors.length ? 'error' : ''"
              name="mail"
            >
            <p class="validation error mt-2">
              {{ errors[0] }}
            </p>
            <p 
              v-for="(err, errIdx) in physician.emailErrors" 
              :key="errIdx"
              class="validation error mt-2"
            >
              {{ err }}
            </p>
          </validation-provider>
          <div class="head-tooltip">
            <p>
              <input
                :id="`optional_info-${index}`"
                v-model="physician.uniqueAllegran"
                type="checkbox"
                name="optional_info" 
                @change="switchAllrgan()" 
              >
              <label :for="`optional_info-${index}`">
                Add unique Allergan APP (optional)
                <i
                  class="fas fa-question-circle fa-xs" 
                  @mouseover="hover(`physician-allergan-${index}`)" 
                  @mouseleave="hoverOff(`physician-allergan-${index}`)"
                />
              </label>
            </p>
            <div 
              :data-tooltip-type="`physician-allergan-${index}`" 
              class="tooltip-black"
            >
              Connect all the doctors in your practice to a single Allergan identifier using these fields, or add an Allergan identifier to each doctor individually.
            </div>
          </div>
          <div
            v-if="physician.uniqueAllegran == true || (physician.allegran.shipto != '' && physician.allegran.soldto != '' && physician.uniqueAllegran != false)" 
            class="row alegren-wrap pr-0"
          >
            <p class="form-group col-md-6">
              <label :for="`soldto-${index}`">SKINCARE PRO SOLDTO</label>
              <input
                :id="`soldto-${index}`"
                v-model="physician.allegran.soldto" 
                type="text"
                :class="allerganError && physician.allegran.soldto == '' ? 'error' : ''" 
                name="soldto"
                @change="changeAllergan" 
              >
            </p>
            <p class="form-group col-md-6">
              <label :for="`shipto-${index}`">SKINCARE PRO SHIPTO</label>
              <input
                :id="`shipto-${index}`"
                v-model="physician.allegran.shipto" 
                type="text" 
                :class="allerganError && physician.allegran.shipto == '' ? 'error' : ''" 
                name="shipto"
                @change="changeAllergan" 
              >
            </p>
            <p 
              v-if="allerganError" 
              class="validation error mt-2 pl-3"
            >
              Please enter your allergan identifier.
            </p>
          </div>
          <p>SKINCARE PRO PHOTO</p>
          <div class="form-group mb-5">
            <ImagePicker 
              :image="physician.avatar"
              :image-preview="physician.avatar.url || physician.avatarPreview"
              :filename="physician.avatarFilename"
              :bordered="true"
              :can-remove="false"
              @image="val => physician.avatar = val"
              @image-preview="val => physician.avatarPreview = val"
              @filename="val => physician.avatarFilename = val"
            />
            <p
              v-if="physician.avatarError" 
              class="validation error mt-2"
            >
              {{ physician.avatarError }}
            </p>
          </div>
        </ValidationObserver>
      </div>
      <div class="row-large">
        <div class="col-xl-5 mb-xl-0 mb-2">
          <a 
            v-if="!isLast()"
            href="#"  
            class="removefrom-prac"
            @click.prevent="removePhysican" 
          ><small>Remove from practice &#8250;</small></a>
        </div>
        <div class="col-xl-7 pr-0">
          <div class="text-right">
            <a 
              href="#" 
              data-action="cancel"
              class="btn btn-bordered right-space"
              @click.prevent="cancel_edit"
            >Cancel</a>
            <a 
              href="#" 
              data-action="save"
              class="btn btn-primary autowidth"
              @click.prevent="update"
            >Save</a>
          </div>
        </div>
      </div>
    </div>
    <div 
      v-if="remove" 
      class="col-sm-9 p-physician-remove pr-0"
    >
      <div class="row-large">
        <div class="col-md-10 pr-0">
          <p>
            Before you remove a skincare pro, you must reassign all their clients to a different
            skincare pro. If you need to reassign some clients (not all) to a new skincare pro, please
            find those clients <router-link 
              :to="{
                name: 'Patients'
              }" 
              tag="a" 
              class="link-as-link"
            >
              here
            </router-link> and reassign.
          </p>
          <p>
            Please reassign all of <strong v-if="physician.last_name != ''">{{ physician.name }}</strong><strong v-else>{{ physician.first_name }}</strong>'s clients to another skincare pro
            in your practice.
          </p>
          <p>
            REASSIGN ALL CLIENTS<br>
            <select 
              v-model="newPhysician" 
              @change="newPhysicianSelected = true"
            >
              <option 
                disabled 
                value=""
              >
                Select from other practice skincare pros
              </option>
              <option 
                v-for="physicianOp in practice.physicians" 
                :key="physicianOp.id" 
                :value="physicianOp.id"
                :class="[physicianOp.id == physician.id ? 'hide' : '']"
              >
                {{ physicianOp.first_name }} {{ physicianOp.last_name }}
              </option>
            </select>
          </p>
          <p 
            v-if="!newPhysicianSelected" 
            class="error validation"
          >
            Please, select new skincare pro
          </p>
        </div>
      </div>
      <div class="row-large">
        <div class="col-md-3">
          <a 
            class="removefrom-prac"
            href="#" 
            @click.prevent="goToEdit" 
          ><small>&#8249; Back</small></a>
        </div>
        <div class="col-md-9">
          <div class="text-right">
            <a 
              href="#" 
              class="btn btn-bordered right-space"
              @click.prevent="goToEdit"
            >Cancel</a>
            <a
              href="#" 
              class="btn btn-primary autowidth"
              @click.prevent="removeAndReassign"
            >Reassign and Remove</a>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <Modal
      :show-modal="showCancelModal"
      class="modal fade dashboard-modal" 
      modal-id="before" 
      @hide-modal-window="showCancelModal = false"
    >
      <img 
        src="../../../../images/warning.png" 
        alt="Warning!" 
        class="warning-icon" 
      >
      <h4 class="modal-title text-center">
        Before you go ...
      </h4>
      <p class="text-center">
        You have unsaved changes. Are you sure you want to continue?
      </p>
      <div class="mb-3">
        <a 
          href="#" 
          class="btn btn-primary w-100"
          @click.prevent="hideModal" 
        >Continue Without Saving</a>
      </div>
      <div class>
        <a
          href="#"
          class="btn btn-bordered w-100"
          data-dismiss="modal"
          @click.prevent="showCancelModal = false"
        >Cancel</a>
      </div>
    </Modal>
    <Modal
      :show-modal="showRemoveModal"
      modal-id="beforeRemove" 
      class="modal fade dashboard-modal" 
      @hide-modal-window="showRemoveModal = false"
    >
      <img 
        src="../../../../images/warning.png" 
        alt="Warning!" 
        class="warning-icon"
      >
      <h4 class="modal-title text-center">
        Remove Member
      </h4>
      <p class="text-center">
        Are you sure want to remove this team member your practice?
      </p>
      <div class="bottom-cta mb-3">
        <a 
          href="#" 
          class="btn btn-primary"
          @click.prevent="reassign" 
        >Remove</a>
      </div>
      <div class="bottom-cta">
        <a 
          href="#" 
          class="btn btn-bordered w-100" 
          data-dismiss="modal"
          @click.prevent="showRemoveModal = false"
        >Cancel</a>
      </div>
    </Modal>
    <div 
      v-if="showCancelModal || showRemoveModal" 
      class="modal-backdrop fade show" 
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../partials/Modal';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  SUPER_ADMIN_GET_PRACTICE,
  UPDATE_PHYSICIAN,
  REASSIGN_REMOVE_PHYSICIAN,
  REMOVE_PHYSICIAN
} from '@/store/practice/actions.types';

import ImagePicker from '@/views/components/ImagePicker';

extend('required', {
  message: 'This field is required'
});

// Component used to show physican data and edit it
// @group Practice
export default {
  name: 'TeamPhysician',
  components: {
    ValidationProvider,
    ValidationObserver,
    Modal,
    ImagePicker
  },
  props: {
    // Phisican object
    physician: {
      type: Object,
      default() {
        return {};
      }
    },
    // Physican index in all physicans array
    index: {
      type: Number,
      default: 0,
    },
    // Can current user edit physician
    canEdit: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showCancelModal: false,
      showRemoveModal: false,
      newPhysicianSelected: true,
      copyedTopPickLink: false,
      sending: false,
      edit: false, 
      remove: false, 
      newPhysician: '',
      currentMember: {
        first_name: '', 
        email: '', 
        allergan: {
          soldto: '',
          shipto: ''
        }, 
        avatar: ''
      },
      allerganError: false
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'practice',
      'allegran'
    ])
  },

  methods: {
    isSuperAdmin() {
      return this.currentUser.roles.includes('super_admin');
    },
    // @vuese
    // Used to show tooltip
    // @arg HTML element data type
    hover(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.add('open');
    },
    // @vuese
    // Used to hide tooltip
    // @arg HTML element data type
    hoverOff(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.remove('open');
    },
    // @vuese
    // Used to store physican data that we already have
    // and show edit form
    editPhysician(){
      this.physician.emailErrors = [];
      this.currentMember.first_name = this.physician.first_name;
      this.currentMember.email = this.physician.email;
      this.currentMember.allergan.soldto = this.physician.allegran.soldto;
      this.currentMember.allergan.shipto = this.physician.allegran.shipto;
      this.currentMember.avatar = this.physician.avatarPreview;
      if(this.currentMember.allergan.shipto != '' && this.currentMember.allergan.soldto != ''){
        this.currentMember.uniqueAllegran = true;
      }
      this.edit = true;
    },

    // @vuese
    // Used to show physican reassign form and hide edit form
    removePhysican() {
      this.edit = false;
      this.remove = true;
    },

    // @vuese
    // Used to show remove modal
    removeAndReassign() {
      if(this.newPhysician != ''){
        this.showRemoveModal = true;
      }else{
        this.newPhysicianSelected = false;
      }
    },
    // @vuese
    // Used to reassign patients to other physican
    reassign() {
      let data = {
        patients: {
          user_id: this.newPhysician,
          old_user_id: this.physician.id
        }
      };
      this.$store.dispatch(REASSIGN_REMOVE_PHYSICIAN, data).then(
        () => {
          this.removePhys();
        }
      ).catch(err => {
        this.flashError(err.data.errors);
      });
    },
    // @vuese
    // Used to remove physican and close form and modal and reload practice info
    removePhys(){
      this.$store.dispatch(REMOVE_PHYSICIAN, this.physician).then(
        () => {
          this.showRemoveModal = false;
          this.remove = false;
          this.edit = false;
          this.$store.dispatch(SUPER_ADMIN_GET_PRACTICE).then(()=>{
            this.flashSuccess('You have successfully removed a physician from your practice.');
          });
        }
      );
    },
    // @vuese
    // Used switch Allergan ether to take it from app, or add unique
    // `ShipTo` and `SoldTo`
    switchAllrgan() {
      if(event.target.checked == false){
        this.physician.allegran.soldto = '';
        this.physician.allegran.shipto = '';
      }
    },
    // @vuese
    // Used to switch back to edit form from remove form
    goToEdit(){
      this.remove = false;
      this.edit = true;
    },
    // @vuese
    // Used to validate and update physican data
    async update(){
      const valid = await this.isValid();
      const validImages = this.validImg();
      const validAllergan = this.allerganValid();

      if (valid && validImages && validAllergan) {
        this.sending = true;

        const data = [],
          physicanPromises = [];
        data.push({
          data: this.prepareData(this.physician),
          id: this.physician.id
        });

        physicanPromises.push(this.$store.dispatch(UPDATE_PHYSICIAN, data[0]));

        Promise.all(physicanPromises)
          .then(() => {
            this.edit = false;
            this.flashSuccess('Physician information updated');
          })
          .catch(err => {
            const errors = err.data.errors;
            const data = err.config.data;
            const errEmail = data.get('physician[email]');
            if (errors.email) {
              if (this.physician.email == errEmail) {
                this.physician.emailErrors = errors.email;
                let elem = document.querySelector('#mail-'+this.index);
                elem.focus();
                elem.blur();
              }
            }
            if (errors.avatar) {
              this.physician.avatarError = errors.avatar;
            }
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },
    // @vuese
    // Check if data was changes and we need 
    // to show confimation modal or just hide edit modal
    cancel_edit(){
      if(this.checkChange()){
        this.showCancelModal = true;
      }else{
        this.edit = false;
      }
    },
    // @vuese
    // Restore data, and hide continue whitout 
    // saving modal and close edit modal
    hideModal(){
      this.physician.first_name = this.currentMember.first_name;
      this.physician.email =  this.currentMember.email;
      this.physician.allegran.soldto = this.currentMember.allergan.soldto;
      this.physician.allegran.shipto = this.currentMember.allergan.shipto;
      this.physician.avatarPreview = this.currentMember.avatar;
      this.physician.uniqueAllegran = this.currentMember.uniqueAllegran;
      this.showCancelModal = false;
      this.edit = false;
    },
    // @vuese
    // Used to check if physican data was changed
    checkChange(){
      if(this.currentMember.first_name != this.physician.first_name){
        return true;
      }else if(this.currentMember.email != this.physician.email){
        return true;
      }else if(this.currentMember.allergan.soldto != this.physician.allegran.soldto){
        return true;
      }else if(this.currentMember.allergan.shipto != this.physician.allegran.shipto){
        return true;
      }else if(this.currentMember.avatar != this.physician.avatarPreview){
        return true;
      }else{
        return false;
      }
    },
    // @vuese
    // Used to check if practice have more than one physican in practice
    isLast(){
      if(this.practice.physicians.length > 1){
        return false;
      }else{
        return true;
      }
    },

    // @vuese
    // Used to check if image is valid
    validImg(){
      const avatar = this.physician.avatar;
      // if ((avatar.hasOwnProperty('url') && !avatar.url) || !avatar) {
      //   this.physician.avatarError = 'Please add physician avatar';
      //   return false;
      // }
      const fileSize = Math.round((avatar.size / 1024));
      if (fileSize > 3072){
        this.physician.avatarError = 'Image upload failed, The maximum file size allowed is 3MB. Please try again.';
        return false;
      }
      return true;
    },
    // @vuese
    // Used to prepare data for saving
    prepareData(physician) {
      let formData = new FormData();
      if (!physician.avatar.hasOwnProperty('url') && !physician.avatar.url)
        formData.append('physician[avatar]', physician.avatar, physician.avatarFilename);

      formData.append('physician[email]', physician.email);
      formData.append('physician[first_name]', physician.first_name);

      if (physician.uniqueAllegran) {
        formData.append(
          'physician[allegran][soldto]',
          physician.allegran.soldto
        );
        formData.append(
          'physician[allegran][shipto]',
          physician.allegran.shipto
        );
      }else{
        formData.append('physician[allegran][soldto]','');
        formData.append('physician[allegran][shipto]','');
      }
      return formData;
    },
    // @vuese
    // Used to check if form is valid
    isValid() {
      return this.$refs.form.validate();
    },
    // @vuese
    // Used to check if allergan data valid
    allerganValid(){
      if((this.physician.allegran.shipto == '' && this.physician.allegran.soldto == '') || (this.physician.allegran.shipto != '' && this.physician.allegran.soldto != '')){
        return true;
      }else{
        this.allerganError = true;
        return false;
      }
    },
    // @vuese
    // Used to hide allergan errors
    changeAllergan(){
      this.allerganError = false;
    },

    copingLink(){
      var text = event.target.href;
      var type_link = event.target.dataset.type;
      var textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.maxHeight = 0;
      
      event.target.parentNode.insertBefore(textArea, event.target.parentNode.childNodes[0]);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      event.target.parentNode.removeChild(textArea);

      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text);
      if(type_link == 'top_picks'){
        this.copyedTopPickLink = true;
        let timerId = setTimeout(() => {
          this.copyedTopPickLink = false;
        }, 2000);
      }
    }
  }
};
</script>
<style scoped lang="scss">  
  .top-picks-draggable-wrapper {
    .row-group:first-child {
      padding-top: 18px !important;
    }
    
    .row-group:last-child {
      border-bottom: 1px solid #d8d8d8 !important; 
      padding-bottom: 18px !important;
    }

    .top-picks-drag-btn-wrapper {
      display: flex;
      cursor: -webkit-grab;
      cursor: grab;
      position: inherit;
      margin: -17px 10px -17px -35px;
      &:active {
        cursor: grabbing;
      }
      .top-picks-drag-btn {
        background-color: #f7f7f8;
        cursor: grab;
        appearance: none;
        border: 0;
        color: #c7c7c7;
        font-size: 20px;
        &:active {
          cursor: grabbing;
        }
      }
    }
  }
</style>
<style scoped>
  .timelink{
    position: absolute;
    padding: 2px;
    margin-top: 25px;
    background: black;
    color: white;
    right: 3%;
  }
  .timelink:before{
    content: "";
    display: block;
    position: absolute;
    left: 39%;
    top: -8px;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid black;
  }
</style>