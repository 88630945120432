<template>
  <div>
    <div class="row no-gutters justify-content-between mb-1 mt-md-4 pb-2">
      <h4>Order Placed</h4>
      <div class="d-flex flex-wrap">
        <div class="filter-group my-1">
          <label for="filter_data">View order placed for:</label>
          <custom-select
            v-model="filterDate"
            :items="filterDateOption"
            class="select-xs"
          />
        </div>
        <div class="filter-group my-1">
          <label for="filter_data">Compare To:</label>
          <custom-select 
            v-model="compareToDate"
            :items="compareToDateOption"
            class="select-xs"
          />
        </div>
        <div class="filter-group my-1">
          <label for="filter_view">View:</label>
          <select-physican
            v-model="physician"
            title="Entire Practice"
            :items="valuesForSelect(physicians)"
            :total="physiciansTotal"
            :loading="isPhysiciansLoading"
            :name="'order-placed-physician-select'"
            :filters="filters"
            @load-more="loadMorePhysicians"
            @click.native="patinetClick"
          />
        </div>
      </div>
    </div>
    <div class="row group-reports">
      <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div class="d-box with-pad view-report">
          <div class="metrics-grid">
            <div class="col with-2cards">
              <p>New Orders Without Subscription</p>
              <div class="two-cards">
                <div class="metric-card">
                  <p class="stat-num">
                    {{ orders.new_orders_without_subscription.placed_current_period }}
                  </p>
                  <p>Orders Placed</p>
                  <p class="percent">
                    <span
                      :class="getReportDeltaClass(orders.new_orders_without_subscription.placed_delta)"
                    >
                      {{ orders.new_orders_without_subscription.placed_delta }}%
                    </span>
                  </p>
                </div>
                <!-- <div class="metric-card">
                  <p class="stat-num">
                    ${{ orders.new_orders_without_subscription.average_current_period }}
                  </p>
                  <p>Average Order Value</p>
                  <p class="percent">
                    <span
                      :class="getReportDeltaClass(orders.new_orders_without_subscription.average_delta)"
                    >
                      {{ orders.new_orders_without_subscription.average_delta }}%
                    </span>
                  </p>
                </div> -->
              </div>
            </div>
          </div>
          <!-- <a
            class="view-more collapsed"
            data-toggle="collapse"
            href="#report_box"
            aria-expanded="true"
          >View Report</a> -->
        </div>
      </div>
      <!-- /. card 1 -->
      <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div class="d-box with-pad view-report">
          <div class="metrics-grid">
            <div class="col with-2cards">
              <p>New Orders With Subscription</p>
              <div class="two-cards">
                <div class="metric-card">
                  <p class="stat-num">
                    {{ orders.new_orders_with_subscription.placed_current_period }}
                  </p>
                  <p>Orders Placed</p>
                  <p class="percent">
                    <span
                      :class="getReportDeltaClass(orders.new_orders_with_subscription.placed_delta)"
                    >
                      {{ orders.new_orders_with_subscription.placed_delta }}%
                    </span>
                  </p>
                </div>
                <!-- <div class="metric-card">
                  <p class="stat-num">
                    ${{ orders.new_orders_with_subscription.average_current_period }}
                  </p>
                  <p>Average Order Value</p>
                  <p class="percent">
                    <span
                      :class="getReportDeltaClass(orders.new_orders_with_subscription.average_delta)"
                    >
                      {{ orders.new_orders_with_subscription.average_delta }}%
                    </span>
                  </p>
                </div> -->
              </div>
            </div>
          </div>
          <!-- <a href class="view-more collapsed">View Report</a> -->
        </div>
      </div>
      <!-- /. card 2 -->
      <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div class="d-box with-pad view-report">
          <div class="metrics-grid">
            <div class="col with-2cards">
              <p>Repeat Orders from Subscription</p>
              <div class="two-cards">
                <div class="metric-card">
                  <p class="stat-num">
                    {{ orders.repeat_orders_from_subscriptions.placed_current_period }}
                  </p>
                  <p>Orders Placed</p>
                  <p class="percent">
                    <span
                      :class="getReportDeltaClass(orders.repeat_orders_from_subscriptions.placed_delta)"
                    >
                      {{ orders.repeat_orders_from_subscriptions.placed_delta }}%
                    </span>
                  </p>
                </div>
                <!-- <div class="metric-card">
                  <p class="stat-num">
                    ${{ orders.repeat_orders_from_subscriptions.average_current_period }}
                  </p>
                  <p>Average Order Value</p>
                  <p class="percent">
                    <span
                      :class="getReportDeltaClass(orders.repeat_orders_from_subscriptions.average_delta)"
                    >
                      {{ orders.repeat_orders_from_subscriptions.average_delta }}%
                    </span>
                  </p>
                </div> -->
              </div>
            </div>
          </div>
          <!-- <a href class="view-more collapsed">View Report</a> -->
        </div>
      </div>
      <!-- /. card 3 -->
      <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div class="d-box with-pad view-report">
          <div class="metrics-grid">
            <p>Cancelled Subscription</p>
            <div class="metric-card">
              <p class="stat-num">
                {{ orders.canceled_subscriptions.current_period }}
              </p>
              <p class="text-soft">
                Subscription Cancelled
              </p>
              <p class="percent">
                <span 
                  :class="getReportDeltaClass(orders.canceled_subscriptions.delta)"
                >
                  {{ orders.canceled_subscriptions.delta }}%
                </span>
              </p>
            </div>
          </div>
          <!-- <a href class="view-more collapsed">View Report</a> -->
        </div>
      </div>
      <!-- /. card 3 -->
    </div>
  </div>
</template>

<style scoped lang="scss">
  .group-reports {
    .d-box {
      height: calc(100% - 15px);
    }
  }
</style>

<script>
import { encodeQueryData, calcDelta, getReportDeltaClass } from '@/helper';
import CustomSelect from '@/views/components/CustomSelect';
import { ReportService } from '@/services/api.service';
import dateOptions from '@/mixins/dateOptions';
import SelectPhysican from '@/views/components/Select';
import physician from '@/mixins/physician';

// @group Reports
export default {
  name: 'OrderPlaced',

  components: {
    CustomSelect,
    SelectPhysican
  },

  mixins: [dateOptions, physician],

  props: {
    practiceCreatedAt: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      filterDate: '',
      filterDateOption: [],
      compareToDate: '',
      compareToDateOption: [],
      physician: '',
      loadOnInit: false,
      physiciansLoaded: false,
      orders: {
        new_orders_with_subscription: {
          average_current_period: 0,
          average_previous_period: 0,
          average_delta: 0,
          placed_current_period: 0,
          placed_previous_period: 0,
          placed_delta: 0
        },
        new_orders_without_subscription: {
          average_current_period: 0,
          average_previous_period: 0,
          average_delta: 0,
          placed_current_period: 0,
          placed_previous_period: 0,
          placed_delta: 0
        },
        repeat_orders_from_subscriptions: {
          average_current_period: 0,
          average_previous_period: 0,
          average_delta: 0,
          placed_current_period: 0,
          placed_previous_period: 0,
          placed_delta: 0
        },
        canceled_subscriptions: {
          current_period: 0,
          previous_period: 0,
          delta: 0
        }
      }
    };
  },

  watch: {
    physician() {
      this.getInfo();
    },

    filterDate: {
      handler() {
        if (this.filterDate && this.compareToDate)
          this.getInfo();
      }
    },

    compareToDate: {
      handler() {
        this.getInfo();
      }
    },

    practiceCreatedAt: {
      immediate: true,
      handler() {
        if (!this.practiceCreatedAt) return;
        const dataOption = this.buildMonthOptions(this.practiceCreatedAt);
        this.filterDateOption = dataOption;
        this.compareToDateOption = dataOption;

        this.filterDate = this.filterDateOption[this.filterDateOption.length - 1].value.map((el) => {
          return el.toString();
        }).join(',');

        setTimeout(() => {
          this.compareToDate = this.getLastYearSameMonth(dataOption).value.map((el) => {
            return el.toString();
          }).join(',');
        });
      }
    }
  },

  methods: {
    // @vuese
    // Used to convert object to get string
    // @arg Query object
    encodeQueryData,
    // @vuese
    // Used to calculate delta
    // @arg Current perriod value
    // @arg / Previous period value
    calcDelta,
    // @vuese
    // Used to get report minus or plus class, based on delta value
    // @arg Delta value
    getReportDeltaClass,

    // @vuese
    // Used to get order placed data
    getInfo() {
      ReportService.getOrdersPlaced(this.buildQueryObj())
        .then(resp => {
          this.orders = resp.data.orders_placed;
          this.addDelta();
        });
    },

    // @vuese
    // Calculate delta value for reports
    addDelta() {
      this.orders.new_orders_with_subscription.placed_delta = this.calcDelta(
        this.orders.new_orders_with_subscription.placed_current_period,
        this.orders.new_orders_with_subscription.placed_previous_period
      );

      this.orders.new_orders_with_subscription.average_delta = this.calcDelta(
        this.orders.new_orders_with_subscription.average_current_period,
        this.orders.new_orders_with_subscription.average_previous_period
      );

      this.orders.new_orders_without_subscription.placed_delta = this.calcDelta(
        this.orders.new_orders_without_subscription.placed_current_period,
        this.orders.new_orders_without_subscription.placed_previous_period
      );

      this.orders.new_orders_without_subscription.average_delta = this.calcDelta(
        this.orders.new_orders_without_subscription.average_current_period,
        this.orders.new_orders_without_subscription.average_previous_period
      );

      this.orders.repeat_orders_from_subscriptions.placed_delta = this.calcDelta(
        this.orders.repeat_orders_from_subscriptions.placed_current_period,
        this.orders.repeat_orders_from_subscriptions.placed_previous_period
      );

      this.orders.repeat_orders_from_subscriptions.average_delta = this.calcDelta(
        this.orders.repeat_orders_from_subscriptions.average_current_period,
        this.orders.repeat_orders_from_subscriptions.average_previous_period
      );

      this.orders.canceled_subscriptions.delta = this.calcDelta(
        this.orders.canceled_subscriptions.current_period,
        this.orders.canceled_subscriptions.previous_period
      );
    },

    // @vuese
    // Convert object to query string
    buildQueryObj() {
      return this.encodeQueryData({
        filters: {
          current_period: this.filterDate.split(',').map(dateItem => {
            return new Date(dateItem).toISOString();
          }),
          previous_period: this.compareToDate.split(',').map(dateItem => {
            return new Date(dateItem).toISOString();
          }),
          physician: this.physician,
        }
      });
    },

    // @vuese
    // Load physican only on click
    patinetClick() {
      if (!this.physiciansLoaded) {
        this.loadMorePhysicians({type: 'search'});
        this.physiciansLoaded = true;
      }
    }
  }
};
</script>