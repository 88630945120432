<template>
  <!-- Addresses -->
  <div class="wrap-table border-table">
    <div class="row row-group row-group-loader-first">
      <div 
        v-if="sending && edits.addresses.default.show"
        class="preloader"
      >
        <i />
      </div>
      <div class="col-sm-4">
        <span class="text-up">
          <strong>Practice Address</strong>
        </span>
      </div>
      <div class="col-sm-8">
        <div
          v-if="!edits.addresses.default.show"
          class="row-large p-addresses-edit-content"
        >
          <div class="col-sm-9">
            <span>
              {{ bulidAddressStr(practice.default_address) }}
            </span>
          </div>
          <div class="col-md-3 action-box">
            <a
              v-if="canUserEdit"
              href="#"
              class="link_action"
              data-block="address"
              @click.prevent="_edit_prac"
            >Edit</a>
          </div>
        </div>
        <div 
          v-else 
          class="row-large p-addresses-edit-form"
        >
          <ValidationObserver
            ref="form"
            v-slot="{ invalid }"
            tag="form"
            @submit.prevent="addPracticeAdress"
          >
            <validation-provider 
              v-slot="{ errors }" 
              name="Street address" 
              rules="required"
            >
              <div class="col-12 form-group pr-0">
                <label for="address">STREET ADDRESS</label>
                <input
                  id="address"
                  v-model="address.default_address.address1"
                  placeholder="113 Main Stret"
                  type="text"
                  :class="errors.length ? 'error' : ''"
                  name="address1"
                >
                <p class="validation error mt-2">
                  {{ errors[0] }}
                </p>
              </div>
            </validation-provider>
            <div class="col-12 form-group pr-0">
              <label for="address2">UNIT OR SUITE NUMBER (OPTIONAL)</label>
              <input
                id="address2"
                v-model="address.default_address.address2"
                placeholder="Suite 600"
                type="text"
                name="address2"
              >
            </div>
            <div class="row-large">
              <validation-provider 
                v-slot="{ errors }" 
                name="City" 
                rules="required"
                class="form-group col-sm-6 pr-0 pr-sm-2"
              >
                <label for="city">CITY</label>
                <input
                  id="city"
                  v-model="address.default_address.city"
                  placeholder="Chicago"
                  type="text"
                  :class="errors.length ? 'error' : ''"
                  name="city"
                >
                <p class="validation error mt-2">
                  {{ errors[0] }}
                </p>
              </validation-provider>
              <div class="form-group col-sm-6 pr-0">
                <label for="state">STATE</label>
                <select
                  id="state"
                  v-model="address.default_address.state"
                  class="select-md col"
                  name="state"
                  @change="validZip(zipTypes.default)"
                >
                  <option value="">
                    Select a State
                  </option>
                  <option
                    v-for="(state, index) in usStates"
                    :key="index"
                    :value="state.name"
                  >
                    {{ state.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-6 pr-0 pr-sm-2">
                <label for="zip">ZIP CODE</label>
                <input
                  id="zip"
                  v-model="address.default_address.zip"
                  placeholder="12345"
                  type="text"
                  :class="defaultZipMessage ? 'error' : ''"
                  name="zip"
                  @input="validZip(zipTypes.default)"
                >
                <p
                  v-if="defaultZipMessage"
                  class="validation error mt-2"
                >
                  {{ defaultZipMessage }}
                </p>
              </div>
              <div class="form-group col-sm-6 pr-0">
                <label for="country">COUNTRY</label>
                <input
                  id="country"
                  v-model="address.default_address.country"
                  placeholder="United States"
                  readonly
                  value="United States"
                  type="text"
                  name="country"
                >
              </div>
            </div>
            <div class="col text-right pr-0">
              <a 
                href="#" 
                class="btn btn-bordered right-space psave-addresses-" 
                data-action="cancel"
                @click.prevent="_edit_prac_address"
              >Cancel</a>
              <a 
                href="#" 
                class="btn btn-primary autowidth"
                data-action="save"
                @click.prevent="_edit_prac_address"
              >Save</a>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <div class="row row-group row-group-loader-last last-row">
      <div 
        v-if="sending && edits.addresses.delivery.show"
        class="preloader"
      >
        <i />
      </div>
      <div class="col-sm-4">
        <span class="text-up">
          <strong>Receiving address</strong>
        </span>
      </div>
      <div class="col-sm-8">
        <div 
          v-if="!edits.addresses.delivery.show" 
          id="p_receiveaddr_edit_content"
          class="row-large" 
        >
          <div class="col-sm-9">
            <p 
              v-if="address_same_as() && bulidAddressStr(practice.delivery_address) != 'None'"
            >
              Same as practice address
            </p>
            <p 
              v-else
            >
              {{ bulidAddressStr(practice.delivery_address) }}
            </p>
            <p 
              v-if="practice.delivery_address"
              class="mb-0"
            >
              <span v-if="practice.delivery_address.note">
                <strong>Notes: </strong>
                {{ practice.delivery_address.note }}
              </span>
            </p>
          </div>
          <div class="col-md-3 action-box">
            <a
              v-if="canUserEdit"
              class="link_action"
              data-block="address_delivery"
              href="#"
              @click.prevent="_edit_prac"
            >Edit</a>
          </div>
        </div>
        <div 
          v-else 
          id="p_receiveaddr_edit_form"
          class="row-large"
        >
          <ValidationObserver
            ref="delivery_form"
            v-slot="{ invalid }"
            tag="form"
            class="col-md-12 pr-0"
            @submit.prevent="addPracticeAdress"
          >
            <div class="form-check-box mb-3">
              <input
                id="same_info"
                v-model="deliverySameAsDefault"
                type="checkbox"
                name="same_info"
              >
              <label for="same_info">Same as Practice Address</label>
            </div>
            <div 
              v-if="!deliverySameAsDefault"
              class="form-group"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Street address "
                rules="required"
                class="form-group"
              >
                <label for="rec_addr_street">Street Address</label>
                <input
                  id="rec_addr_street"
                  v-model="address.delivery_address.address1"
                  placeholder="113 Main Stret"
                  type="text"
                  :class="errors.length ? 'error' : ''"
                  name="street"
                >
                <p class="validation error mt-2">
                  {{ errors[0] }}
                </p>
              </validation-provider>
            </div>
            <div 
              v-if="!deliverySameAsDefault"
              class="form-group" 
            >
              <label for="rec_addr_unit">Unit or Suite Number (Optional)</label>
              <input
                id="rec_addr_unit"
                v-model="address.delivery_address.address2"
                placeholder="Suite 600"
                type="text"
                name="suite1"
              >
            </div>
            <div 
              v-if="!deliverySameAsDefault"
              class="row-large" 
            >
              <validation-provider 
                v-slot="{ errors }" 
                class="form-group col-sm-6 pr-0 pr-sm-2" 
                name="City " 
                rules="required"
              >
                <label for="paddr_city">City</label>
                <input
                  id="paddr_city"
                  v-model="address.delivery_address.city"
                  placeholder="Chicago"
                  type="text"
                  :class="errors.length ? 'error' : ''"
                  name="city"
                >
                <p class="validation error mt-2">
                  {{ errors[0] }}
                </p>
              </validation-provider>
              <div class="form-group col-sm-6 pr-0">
                <label for="paddr_state">State</label>
                <select
                  id="paddr_state"
                  v-model="address.delivery_address.state"
                  class="select-lg col"
                  name="state"
                  @change="validZip(zipTypes.delivery)"
                >
                  <option value="">
                    Select a State
                  </option>
                  <option
                    v-for="(state, index) in usStates"
                    :key="index"
                    :value="state.name"
                  >
                    {{ state.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-6 pr-0 pr-sm-2">
                <label for="paddr_zip">Zip Code</label>
                <input
                  id="paddr_zip"
                  v-model="address.delivery_address.zip"
                  placeholder="12345"
                  type="text"
                  :class="deliveryZipMessage ? 'error' : ''"
                  name="zip"
                  @input="validZip(zipTypes.delivery)"
                >
                <p
                  v-if="deliveryZipMessage"
                  class="validation error mt-2"
                >
                  {{ deliveryZipMessage }}
                </p>
              </div>
              <div class="form-group col-sm-6 pr-0">
                <label for="paddr_country">Country</label>
                <input
                  id="country1"
                  v-model="address.delivery_address.country"
                  placeholder="United States"
                  readonly
                  value="United States"
                  type="text"
                  name="ext"
                >
              </div>
            </div>
            <div class="form-group">
              <label for="rec_addr_notes">Delivery Notes</label>
              <textarea
                id="rec_addr_notes"
                v-model="address.delivery_address.note"
                class="col"
                name="notes"
                placeholder="Enter any special delivery instructions"
                cols="30"
                rows="4"
              />
            </div>
            <div class="text-right">
              <a 
                id="p_receiveaddr_save"
                href="#" 
                class="btn btn-bordered right-space" 
                data-action="cancel"
                @click.prevent="_edit_prac_delivery_address"
              >Cancel</a>
              <a 
                href="my-practice-admin-updated.html" 
                class="btn btn-primary autowidth"
                data-action="save"
                @click.prevent="_edit_prac_delivery_address"
              >Save</a>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <Modal
      :show-modal="showCancelModal"
      modal-id="before"
      class="modal fade dashboard-modal" 
      @hide-modal-window="showCancelModal = false"
    >
      <img 
        src="../../../../images/warning.png" 
        alt="Warning!" 
        class="warning-icon" 
      >
      <h4 class="modal-title text-center">
        Before you go ...
      </h4>
      <p class="text-center">
        You have unsaved changes. Are you sure you want to continue?
      </p>
      <div class="mb-3">
        <a 
          href="#" 
          class="btn btn-primary w-100"
          @click.prevent="hideModal" 
        >Continue Without Saving</a>
      </div>
      <div class>
        <a 
          href="#"
          class="btn btn-bordered w-100"
          data-dismiss="modal"
          @click.prevent="showCancelModal = false"
        >Cancel</a>
      </div>
    </Modal>
    <div 
      v-if="showCancelModal" 
      class="modal-backdrop fade show"
    />
  </div>
  <!-- End Addresses -->
</template>

<style>
  .fade-enter-active {
    transition: opacity .5s;
  }
  .fade-leave-active {
    opacity: 0;
  }
  .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
  @keyframes bounceIn {
    0% {
        transform: scale(0.1);
        opacity: 0
    }
    60% {
        transform: scale(1.2);
    }
    100% {
        transform: 1;
        opacity: 1;
    }
  }
</style>

<script>
import { mapGetters } from 'vuex';
import Modal from '../partials/Modal';
import {
  ADD_ADDRESS,
  UPDATE_ADDRESS
} from '@/store/practice/actions.types';
// import { 
//   default_address,
//   delivery_address
// } from '@/store/practice/initialObject';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import {
  usStates,
  isZipValidForState,
  copyDefaultToDelivery,
  bulidAddressStr
} from '@/helper';
import googleAutocompleate from '@/mixins/googleAutocompleate';

// Install rules
extend('required', required);

// Component to show and update practice addresses
// This page includes Default address and Delivery address
// @group Practice
export default {
  name: 'Addresses',

  components: {
    Modal,
    ValidationProvider,
    ValidationObserver
  },

  mixins: [googleAutocompleate],

  props: {
    // Property that show is current suer can edit address
    canUserEdit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      edits: {
        current: '',
        addresses: {
          delivery: {
            show: false,
            address: {}
          },
          default: {
            show: false,
            address: {}
          }
        }
      },
      showCancelModal: false,

      sending: false,
      zipTypes: {
        default: 'default',
        delivery: 'delivery'
      },
      deliverySameAsDefault: false,
      usStates,
      defaultZipMessage: '',
      deliveryZipMessage: '',
      curentOffSet: 0
    };
  },
  
  computed: {
    ...mapGetters(['currentUser', 'practice', 'address'])
  },

  watch: {
    deliverySameAsDefault() {
      if (this.deliverySameAsDefault) {
        this.address.delivery_address = copyDefaultToDelivery(
          this.address.default_address,
          this.address.delivery_address
        );
      } else {
        setTimeout(() => {
          // @vuese
          // Used to init google automplrete function
          this.initAutocomplete('rec_addr_street', this.shippingComponent, 'address.delivery_address');
        });
      }
    }
  },

  methods: {
    // @vuese
    // Used to convert address object to string, if object have empty  props
    // return word `None`
    // @arg address object
    bulidAddressStr,

    // Check if practice and receiving addresses same
    address_same_as() {
      let address = { ...this.address.default_address },
        delivery = { ...this.address.delivery_address };
      delete delivery.note;
      delete delivery.type;
      delete address.type;
      if (JSON.stringify(address) === JSON.stringify(delivery)) {
        return true;
      } else {
        return false;
      }
    },

    // @vuese
    // Used to show edit form and save old data in case if we do not want to save it
    // @arg param that show which form we editing
    _edit_prac(event) {
      switch (event.target.getAttribute('data-block')) {
      case 'address':
        this.edits.addresses.default.address = {
          ...this.address.default_address
        };
        this.edits.addresses.default.show = true;
        setTimeout(() => {
          this.initAutocomplete('address', this.shippingComponent, 'address.default_address');
        });
        break;
      case 'address_delivery':
        this.edits.addresses.delivery.address = {
          ...this.address.delivery_address
        };
        this.edits.addresses.delivery.show = true;
        setTimeout(() => {
          this.initAutocomplete('rec_addr_street', this.shippingComponent, 'address.delivery_address');
        });
        break;
      }
    },

    // @vuese
    // Used to close address form and also save data or cancel editing form
    // in case if data was changed and we cancel editing it will trigger modal
    // that tell that you will loose data
    // @arg event
    async _edit_prac_address(event) {
      switch (event.target.getAttribute('data-action')) {
      case 'save':
        if (await this.isValid('form') && this.validZip(this.zipTypes.default)) {
          this.sending = true;
          const default_address = this.address.default_address;
          let defaultAddressPromise = null;
          if (!default_address.type) {
            default_address.type = 'default';
          }
          if (default_address.action && default_address.action == 'create') {
            defaultAddressPromise = this.$store.dispatch(
              ADD_ADDRESS,
              default_address
            );
          } else {
            defaultAddressPromise = this.$store.dispatch(
              UPDATE_ADDRESS,
              default_address
            );
          }
          Promise.all([defaultAddressPromise])
            .then(values => {
              this.practice.default_address = this.address.default_address;
              this.edits.addresses.default.show = false;
              this.flashSuccess('Practice address updated');
            })
            .catch(err => {
              this.flashWarning('Error updating address');
            })
            .finally(() => {
              this.sending = false;
            });
        }
        break;
      case 'cancel':
        if ( JSON.stringify(this.address.default_address) !== JSON.stringify(this.edits.addresses.default.address)) {
          this.edits.current = 'address';
          this.showCancelModal = true;
        } else {
          this.edits.addresses.default.show = false;
        }
        break;
      }
    },

    // @vuese
    // Used to close reciving form and also save data or cancel editing form
    // in case if data was changed and we cancel editing it will trigger modal
    // that tell that you will loose data
    // @arg event
    async _edit_prac_delivery_address(event) {
      switch (event.target.getAttribute('data-action')) {
      case 'save':
        if (await this.isValid('delivery_form') && this.validZip(this.zipTypes.delivery)) {
          this.sending = true;
          let delivery_address = this.address.delivery_address;
          if (!delivery_address.type) {
            delivery_address.type = 'delivery';
          }
          let deliveryAddressPromise = null;
          if (
            delivery_address.action &&
            delivery_address.action == 'create'
          ) {
            deliveryAddressPromise = this.$store.dispatch(
              ADD_ADDRESS,
              delivery_address
            );
          } else {
            deliveryAddressPromise = this.$store.dispatch(
              UPDATE_ADDRESS,
              delivery_address
            );
          }

          Promise.all([deliveryAddressPromise])
            .then(values => {
              this.practice.delivery_address = this.address.delivery_address;
              this.edits.addresses.delivery.show = false;
              this.flashSuccess('Practice delivery address updated');
            })
            .catch(err => {
              console.log(err);
              this.flashWarning('Error updating delivery address');
            })
            .finally(() => {
              this.sending = false;
            });
        }
        break;
      case 'cancel':
        if (
          JSON.stringify(this.address.delivery_address) !==
          JSON.stringify(this.edits.addresses.delivery.address)
        ) {
          this.edits.current = 'address_delivery';
          this.showCancelModal = true;
        } else {
          this.edits.addresses.delivery.show = false;
        }
        break;
      }
    },

    // @vuese
    // Used to hide cancel modal 
    hideModal() {
      switch (this.edits.current) {
      case 'address':
        this.practice.default_address = this.edits.addresses.default.address;
        this.address.default_address = this.edits.addresses.default.address;
        this.showCancelModal = false;
        this.edits.addresses.default.show = false;
        break;
      case 'address_delivery':
        this.practice.delivery_address = this.edits.addresses.delivery.address;
        this.address.delivery_address = this.edits.addresses.delivery.address;
        this.showCancelModal = false;
        this.edits.addresses.delivery.show = false;
        break;
      }
    },

    // @vuese
    // Used to check if form is valid
    // @arg form ref
    isValid(form) {
      return this.$refs[form].validate();
    },
    // @vuese
    // Check if zip code is valid for state
    // @arg Zip type
    validZip(type) {
      const { default_address, delivery_address } = this.address;
      if (type == this.zipTypes.default) {
        const { valid, message } = this.validateZip(
          default_address.zip,
          default_address.state
        );
        this.defaultZipMessage = message;
        return valid;
      } else if (type == this.zipTypes.delivery) {
        const { valid, message } = this.validateZip(
          delivery_address.zip,
          delivery_address.state
        );
        this.deliveryZipMessage = message;
        return valid;
      }
    },
    // @vuese
    // Used to show and hicde zip validation messages
    // @arg `ZipCode` and `StateCode`
    validateZip(zipCode, stateCode) {
      let valid = false,
        message = '';
      if (!stateCode) {
        message = 'Please select state first';
        valid = false;
      } else if (!isZipValidForState(zipCode, stateCode)) {
        message = `Please enter valid zip for ${
          this.findState(stateCode).name
        }`;
        valid = false;
      } else {
        valid = true;
      }
      return { valid, message };
    },
    // @vuese
    // Used to find state object by state code
    // @arg State code
    findState(stateCode) {
      return this.usStates.find(state => state.name == stateCode);
    }
  }
};
</script>