<template>
  <footer class="foot">
    <div class="footer-container">
      <a
        href="/privacy"
        class="footer-link"
        @click.prevent="redirectTo('/privacy')"
      >Privacy Policy</a>
      <a
        href="/termsofuse"
        class="footer-link"
        @click.prevent="redirectTo('/termsofuse')"
      >Terms of Use</a>
      <a
        v-if="isRole('authorized_principal')"
        href="/affiliate"
        class="footer-link"
        @click.prevent="redirectTo('/affiliate')"
      >Affiliate Agreement</a>
      <a
        href="/standard_tnc"
        class="footer-link"
        @click.prevent="redirectTo('/standard_tnc')"
      >Standard Supplemental Terms and Conditions</a>
      <a
        href="/contact"
        class="footer-link"
        @click.prevent="redirectTo('/contact')"
      >Contact Us</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    redirectTo(route) {
      window.open(route, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.foot {
  background-color: #32384f;
  padding: 1rem;
  text-align: center;
  margin-bottom: -10rem;
  position: absolute;
  width: 100%;
}

.footer-container {
  flex-wrap: wrap;
  float: right;
  color: white;
  flex-direction: column;
  align-items: flex-end;
}

.footer-link {
  padding-left: 25px; 
  margin-bottom: 0.2rem;
  text-decoration: none;
  color: white;
  cursor: pointer; /* Change cursor to pointer on hover */
}

.footer-link:hover {
  text-decoration: underline;
}
</style>
