<template>
  <div class="content-wrap">
    <Notifications />
    <h2>
      <span v-if="order.patient">{{ order.patient.name }}</span> Order 
      <span v-if="order.shopify">{{ order.shopify.order_number }}</span>
    </h2>
    <ul class="breadcrumb-page">
      <li class="crumb">
        <router-link
          :to="{
            name: 'Orders'
          }"
        >
          All Orders
        </router-link>
      </li>
      <li class="crumb active">
        <span v-if="order.patient">{{ order.patient.name }}</span> Order 
        <span v-if="order.shopify">{{ order.shopify.order_number }}</span>
      </li>
    </ul>
    <div class="row row-layout-370">
      <div class="col-xl-8">
        <h5 
          v-if="order.shopify"
        >
          Order {{ order.shopify.order_number }}
        </h5>
        <div class="wrap-table border-table">
          <div class="row row-group">
            <div class="col-sm-4">
              <span class="text-up">Client name</span>
            </div>
            <div class="col-sm-8">
              <div class="row-large">
                <div class="col-md-8">
                  <span v-if="order.patient">{{ order.patient.name }}</span>
                </div>
                <div class="col-md-4 action-box">
                  <router-link
                    v-if="order.patient"
                    :to="{
                      name: 'PatientView',
                      params: {
                        id: order.patient.id
                      }
                    }"
                    class="link_action"
                  >
                    View Client
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-group">
            <div class="col-sm-4">
              <span class="text-up">Skincare Pro</span>
            </div>
            <div class="col-sm-8">
              <div class="row-large">
                <div class="col-md-10">
                  <span v-if="order.physician">{{ order.physician.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-group">
            <div class="col-sm-4">
              <span class="text-up">Order placed</span>
            </div>
            <div class="col-sm-8">
              <div class="row-large">
                <div class="col-md-10">
                  <span v-if="order.shopify">{{ order.shopify.created_at }}</span>
                </div>
              </div>
            </div>
          </div>
          <div 
            class="row row-group" 
            style="display: none"
          >
            <div class="col-sm-4">
              <span class="text-up">Order status</span>	
            </div>
            <div class="col-sm-8">
              <div class="row-large">
                <div class="col-md-10">
                  <span>On Its Way</span>
                </div>
              </div>
            </div>
          </div>
          <div 
            class="row row-group"
            style="display:none"
          >
            <div class="col-sm-4">
              <span class="text-up">Fulfillment status</span>
            </div>
            <div class="col-sm-8">
              <div class="row-large">
                <div class="col-md-10">
                  <span class="text-capitalize">
                    {{ order.shopify && order.shopify.fulfillment_status ? order.shopify.fulfillment_status : 'Unfulfilled' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-group">
            <div class="col-sm-4">
              <span class="text-up">Ship to address</span>
            </div>
            <div class="col-sm-8">
              <div class="row-large">
                <div class="col-md-10">
                  <span>{{ addressRepr('shipping') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-group">
            <div class="col-sm-4">
              <span class="text-up">Bill to address</span>
            </div>
            <div class="col-sm-8">
              <div class="row-large">
                <div class="col-md-10">
                  <span>{{ addressRepr('billing') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-group">
            <div class="col-sm-4">
              <span class="text-up">Payment method</span>
            </div>
            <div class="col-sm-8">
              <div class="row-large">
                <div class="col-md-10">
                  <span
                    v-if="order.shopify && order.shopify.payment_method && order.shopify.payment_method.credit_card_number"
                  >
                    <span class="text-upercase">
                      {{ order.shopify.payment_method.credit_card_company }}
                    </span> 
                    ending in {{ order.shopify.payment_method.credit_card_number }}
                  </span>
                  <span v-else>None</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /. Table Orders Information 1  -->
        <div v-if="order.shopify && order.shopify.fulfillments.length > 0">
          <div
            v-for="(fulfillment, index) in order.shopify.fulfillments"
            :key="index"
            class="wrap-table border-table table-header"
          >
            <div class="row no-gutters header-box">
              <!-- <div class="col-sm-3">
                <p class="text-up">
                  Est. delivery date
                </p>
                <span>{{ order.created_at }}</span>
              </div> -->
              <!-- <div class="col-sm-2">
                <p class="text-up">
                  Status
                </p>
                <span>{{ fulfillment.shipment_status }}</span>
              </div> -->
              <div class="col-sm-4">
                <p class="text-up">
                  Delivery method
                </p>
                <span v-if="fulfillment.tracking_company">{{ fulfillment.tracking_company }}</span>
                <span v-else>Not Available</span>
              </div>
              <div class="col-sm-3">
                <p class="text-up">
                  Tracking number
                </p>
                <a 
                  v-if="fulfillment.tracking_url" 
                  :href="fulfillment.tracking_url"
                >{{ fulfillment.tracking_number }}</a>
                <span v-else>Not Available</span>
              </div>
            </div>
            <div class="content-table">
              <table class="table product-table">
                <tbody>
                  <tr
                    v-for="line_item in fulfilledLineItems(fulfillment.line_items)"
                    :key="line_item.id"
                  >
                    <td>
                      <div class="lg-image">
                        <img
                          v-if="line_item.shopify_product.image !== null"
                          :src="line_item.shopify_product.image"
                          :alt="order.shopify.name"
                        >
                      </div>
                    </td>
                    <td>
                      <p class="title-td text-up">
                        {{ line_item.shopify_product.title }}
                      </p>
                      <p v-if="line_item.shopify_variant.title !== 'Default Title'">
                        {{ line_item.shopify_variant.title }}
                      </p>
                      <p class="title-td text-up ">
                        Subscription Frequency
                      </p>
                      <p>
                        {{ line_item.selling_plan }}
                      </p>
                    </td>
                    <td>
                      <p class="title-td text-up">
                        Product price
                      </p>
                      <p>{{ line_item.price_per_item }}</p>
                    </td>
                    <td>
                      <p class="title-td text-up">
                        Quantity
                      </p>
                      <p>{{ line_item.quantity }}</p>
                    </td>
                    <td>
                      <p class="title-td text-up">
                        Total price
                      </p>
                      <p>{{ line_item.price }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          v-if="getUnfulfilledLineItems.length"
          class="wrap-table border-table table-header"
        >
          <div class="row no-gutters header-box">
            <!-- <div class="col-sm-3">
              <p class="text-up">
                Est. delivery date
              </p>
            </div>
            <div class="col-sm-2">
              <p class="text-up">
                Status
              </p>
            </div> -->
            <div class="col-sm-4">
              <p class="text-up">
                Delivery method
              </p>
              <span>Not Available</span>
            </div>
            <div class="col-sm-3">
              <p class="text-up">
                Tracking number
              </p>
              <span>Not Available</span>
            </div>
          </div>
          <div class="content-table">
            <table class="table">
              <tbody>
                <tr
                  v-for="line_item in getUnfulfilledLineItems"
                  :key="line_item.id"
                >
                  <td>
                    <div class="lg-image text-center">
                      <img
                        v-if="line_item.shopify_product.image !== null"
                        :src="line_item.shopify_product.image"
                        :alt="order.shopify && order.shopify.name ? order.shopify.name : 'Image'"
                      >
                    </div>
                  </td>
                  <td>
                    <p class="title-td text-up">
                      Product name
                    </p>
                    <p>
                      {{ line_item.shopify_product.title }}
                    </p>
                    <p v-if="line_item.shopify_variant.title !== 'Default Title'">
                      {{ line_item.shopify_variant.title }}
                    </p>

                    <p 
                      class="title-td text-up"
                      style="margin-top: 10px"
                    >
                      Subscription Frequency
                    </p>
                    <p>
                      {{line_item.selling_plan}}
                    </p>
                  </td>
                  <td>
                    <p class="title-td text-up">
                      Product price
                    </p>
                    <p>{{ line_item.shopify_variant ? `$${ line_item.shopify_variant.price}` : line_item.price_per_item }}</p>
                  </td>
                  <td>
                    <p class="title-td text-up">
                      Quantity
                    </p>
                    <p>{{ line_item.quantity }}</p>
                  </td>
                  <td>
                    <p class="title-td text-up">
                      Total price
                    </p>
                    <p>{{ line_item.price }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /. Table Orders Information 2  -->
      </div>
      <div class="col-xl-4 aside-sticky">
        <h5>Order Total</h5>
        <div class="aside-box">
          <div class="row">
            <div class="col-6">
              <p>Subtotal</p>
              <p>Tax</p>
            </div>
            <div class="col-6 text-right">
              <p 
                v-if="order.shopify"
              >
                {{ order.shopify.subtotal_price }}
              </p>
              <p 
                v-else
              >
                N/A
              </p>
              <p
                v-if="order.shopify"
              >
                {{ order.shopify.total_tax }}
              </p>
              <p
                v-else
              >
                N/A
              </p>
            </div>
          </div>
          <hr class="mt-0 mb-3">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">
                Order Total
              </h3>
            </div>
            <div class="col-6 text-right">
              <h3
                v-if="order.shopify"
                class="mb-0"
              >
                {{ order.shopify.total_price }}
              </h3>
            </div>
          </div>
          <hr
            v-if="order.status == 'failed' && order.error_message"
            class="mt-3 mb-3"
          >
          <div
            v-if="order.status == 'failed' && order.error_message"
            class="row"
          >
            <div class="col-12">
              <a 
                href="#"
                class="btn btn-block btn-primary"
                @click.prevent="retryCharge"
              >
                Retry Placing Order
              </a>
            </div>
          </div>
        </div>
        <h5>RegimenPro® Return Policy</h5>
        <div class="aside-box">
          <p class="mb-0">
            In order to obtain a refund on a Warranty-eligible product, 
            the original purchaser of the Product must 
            <strong>first contact a representative of RegimenPro's 
              Customer Service at 
              <a 
                href="mailto: customerservice@regimen.com" 
                class="btn-link"
              >customerservice@regimenpro.com</a> or call 
              <a 
                href="tel:855-576-1800"
                class="btn-link"
              >855-576-1800</a>
            </strong>
            to explain the reason for seeking a refund/return. <br><br>
            The full return policy can be read 
            <a
              target="_blank"
              :href="`${shopUrl}/pages/return-policy`"
              class="btn-link"
            >here</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .table img {
    max-height: 75px;
  }
  .text-up {
    font-size: 12px;
    font-weight: bold !important;
    & + * {
      font-weight: 300;
      color: #979797
    }
    & + a:hover {
      color: #a989de;
    }
  }
  .wrap-table.border-table .content-table {
    td {
      padding-left: 5px;
      padding-right: 5px;
      &:nth-child(2) {
        width: 25%;
      }
    }
  }
</style>

<script>
import { get } from 'lodash';
import {
  OrdersService,
  CheckoutService
} from '@/services/api.service';

// @vuese
// @group Orders
export default {
  name: 'ShowOrder',

  data() {
    return {
      order: {}
    };
  },

  computed: {
    getUnfulfilledLineItems: function () {
      if (!this.order.line_items) return [];
      if (!this.order.shopify) return this.order.line_items;

      const fulfilledLineItemsIds = this.order.shopify.fulfillments
        .map(f => f.line_items).flat();

      return this.order.line_items
        .filter(l_i => !fulfilledLineItemsIds
          .includes(l_i.shopify_variant.shopify_variant_id));
    }
  },

  created() {
    this.fetchOrder();
  },

  beforeMount() {
    window.scrollTo(0, 0);
  },

  methods: {
    // @vuese
    // Used to fetch Order data from DB
    fetchOrder() {
      OrdersService.get(this.$route.params.id).then(this.setOrder);
    },

    // @vuese
    // Used to save order to local store and show some warnings if we have one
    // @arg Ajax response
    setOrder({ data }) {
      this.order = data.order;
      if (this.order.status == 'failed' && this.order.error_message)
        this.flashError(this.order.error_message);

      let warnings = [];
      if(this.order.shopify) {
        if (this.order.shopify.financial_status === 'refunded')
          warnings.push('One or more products in this order have been refunded to the customer');
        if (this.order.shopify.cancelled_at)
          warnings.push(`Order was cancelled at: ${new Date(this.order.shopify.cancelled_at).format('MM/DD/YYYY')}`);
      }

      if (warnings.length) 
        this.flashWarning(warnings.join('<br>'));
    },

    // @vuese
    // Used to convert address object to string
    // @arg Address type `shipping` or `billing`
    addressRepr(type='shipping') {
      const address_keys = ['name', 'address1', 'address2', 'city', 'state', 'zip'];
      return  this.order.shopify && this.order.shopify[`${type}_address`] ? 
        address_keys.map(key => this.order.shopify[`${type}_address`][key]).filter(item => typeof item !== 'undefined' && item).join(', ')
        : 'None';
    },

    // @vuese
    // Used to return fullfiled line items
    // @arg Array of line items
    fulfilledLineItems(line_items) {
      return this.order.line_items.filter(l_i => line_items.includes(l_i.shopify_variant.shopify_variant_id) );
    },

    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      return this.isRole('super_admin') ? `/${this.order.practice.id}` : '';
    },

    async retryCharge() {
      try {
        await CheckoutService.complete(
          this.order.patient.id,
          this.getRequestPracticeId(),
          { order_id: this.order.id, charge_status: 'ERROR' }
        );
        this.flashSuccess('Order placed successfully.');
      } catch(exception) {
        if (get(exception, 'response.data.error')) {
          this.flashError(get(exception, 'response.data.error'));
        } else if(get(exception, 'data.error')) {
          this.flashError(get(exception, 'data.error'));
        } else if(get(exception, 'data.errors')) {
          this.flashError(`${Object.values(exception.data.errors).join('. ')}. Please fix it first.`);
        } else if(get(exception, 'data')) {
          this.flashError(`${JSON.stringify(exception.data)}. Error happened. Please fix it first.`);
        } else if(get(exception, 'response.data.errors')) {
          this.flashError(`${Object.values(exception.response.data.errors).join('. ')}. Please fix it first.`);
        } else if(get(exception, 'response.data')) {
          this.flashError(`${JSON.stringify(exception.response.data)}. Error happened. Please fix it first.`);
        } else {
          this.flashError('Error happened. Please fix it first.');
        }
      }
    }
  }
};
</script>
