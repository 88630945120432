<template>
  <div class="content-wrap">
    <h2>Invite New Practice Member</h2>
    <ul class="breadcrumb-page">
      <li class="crumb">
        <router-link
          :to="{
            name: 'MyPractice'
          }"
          tag="a"
        >
          My Practice
        </router-link>
      </li>
      <li class="crumb active">
        Invite New Practice Member
      </li>
    </ul>
    <div
      v-if="!superAdminShowPractice()"
      class="wrap-table border-table"
    >
      Please choose a practice first.
    </div>
    <div
      v-if="superAdminShowPractice()"
      class="row"
    >
      <div class="col-xl-6 col-lg-7 radio-actions">
        <h5>Select Practice Member Type</h5>
        <div class="d-box with-lpad mb-sm-5 mb-4">
          <div class="form-check check-radio mb-sm-4 input-radio">
            <input
              id="exampleRadios1"
              v-model="radioSelect"
              class="form-check-input"
              type="radio"
              name="invite"
              value="physician"
              @change="handleRadioChange"
            >
            <label
              class="form-check-label"
              for="exampleRadios1"
            >
              <strong>Skincare Pro</strong>
            </label>
            <p>
              Every client you add will be associated with a skincare pro.
              They will see their name and photo or their account.
            </p>
          </div>
          <div class="form-check check-radio input-radio">
            <input
              id="exampleRadios2"
              v-model="radioSelect"
              class="form-check-input"
              type="radio"
              name="invite"
              value="staff"
              @change="handleRadioChange"
            >
            <label
              class="form-check-label"
              for="exampleRadios2"
            >
              <strong>Staff</strong>
            </label>
            <p>
              Staff will be able to add clients and manage their regimens. They can do everythig a skincare pro can,
              bit they won't be associated with a client. The information entered here will not be shown to clients.
            </p>
          </div>
        </div>
        <div id="groups-rad">
          <div 
            v-if="sending"
            class="preloader"
          >
            <i />
          </div>
          <div
            v-if="radioSelect == 'physician'"
            id="group1"
          >
            <h5>Enter Skincare Pro information</h5>
            <ValidationObserver
              ref="form"
              v-slot="{ invalid }"
              tag="div"
              class="d-box with-lpad mb-sm-5 mb-4"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Physician name"
                rules="required"
                class="form-group"
              >
                <label for="phname">Skincare Pro Name</label>
                <input
                  id="phname"
                  v-model="physicianObj.first_name"
                  placeholder="Dr. Jean Grey"
                  type="text"
                  :class="errors.length ? 'error' : ''"
                  name="name"
                >
                <p class="validation error mt-2">
                  {{ errors[0] }}
                </p>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="Physician email"
                rules="email|required"
                class="form-group"
              >
                <div class="head-tooltip">
                  <label for="phmail">
                    Skincare Pro Email
                    <i
                      class="fas fa-question-circle fa-xs"
                      @mouseover="hover('invite-physician-email')"
                      @mouseleave="hoverOff('invite-physician-email')"
                    />
                  </label>
                  <div
                    data-tooltip-type="invite-physician-email"
                    class="tooltip-black"
                  >
                    The skincare pro's email address is never shared with clients.
                  </div>
                </div>
                <input
                  id="phmail"
                  v-model="physicianObj.email"
                  placeholder="jeangrey@practice.com"
                  type="text"
                  name="mail"
                  :class="errors.length || physicianObj.emailErrors.length ? 'error' : ''"
                >
                <p class="validation error mt-2">{{ errors[0] }}</p>
                <p
                  v-for="(err, errIdx) in physicianObj.emailErrors"
                  :key="errIdx"
                  class="validation error mt-2"
                >
                  {{ err }}
                </p>
              </validation-provider>

              <div class="form-check-box mb-3">
                <input
                  id="optional_info"
                  v-model="physicianObj.uniqueAllegran"
                  type="checkbox"
                  name="optional_info"
                >
                <label for="optional_info">
                  Add unique Allergan APP identifier (optional)
                  <i
                    class="fas fa-question-circle fa-xs"
                    @mouseover="hover('invite-physician-allergan')"
                    @mouseleave="hoverOff('invite-physician-allergan')"
                  />
                </label>
                <div
                  data-tooltip-type="invite-physician-allergan"
                  class="tooltip-black"
                >
                  Connect all the skincare pros in your practice to a single Allergan identifier using these fields, or add an Allergan identifier to each doctor individually.
                </div>
              </div>

              <div
                v-if="physicianObj.uniqueAllegran"
                class="form-group row"
              >
                <div class="col-md-6">
                  <label for="soldto">SKINCARE PRO SOLDTO</label>
                  <input
                    id="soldto"
                    v-model="physicianObj.allegran.soldto"
                    type="text"
                    :class="allegranError && physicianObj.allegran.soldto == '' ? 'error' : ''"
                    name="soldto"
                    @change="changeAllergan"
                  >
                </div>
                <div class="col-md-6">
                  <label for="shipto">SKINCARE PRO SHIPTO</label>
                  <input
                    id="shipto"
                    v-model="physicianObj.allegran.shipto"
                    type="text"
                    :class="allegranError && physicianObj.allegran.shipto == '' ? 'error' : ''"
                    name="shipto"
                    @change="changeAllergan"
                  >
                </div>
                <p
                  v-if="allegranError"
                  class="validation error mt-2 pl-3"
                >
                  Please enter your allergan identifier.
                </p>
              </div>
              <div class="form-group mb-5">
                <label for="phphoto">Skincare Pro Photo</label>

                <ImagePicker
                  :image="physicianObj.avatar"
                  :image-preview="physicianObj.avatarPreview"
                  :filename="physicianObj.avatarFilename"
                  :bordered="true"
                  :can-remove="true"
                  @image="val => physicianObj.avatar = val"
                  @image-preview="val => physicianObj.avatarPreview = val"
                  @filename="val => physicianObj.avatarFilename = val"
                />
              </div>
            </ValidationObserver>
          </div>
          <div
            v-if="radioSelect == 'staff'"
            id="group2"
          >
            <h5>Enter Staff information</h5>
            <ValidationObserver
              ref="staffForm"
              v-slot="{ invalid }"
              tag="div"
              class="d-box with-lpad mb-sm-5 mb-4"
            >
              <p>The information entered here will not be shown to clients.</p>
              <validation-provider
                v-slot="{ errors }"
                name="Staff name"
                rules="required"
                class="form-group"
              >
                <label for="pname">Staff Name</label>
                <input
                  id="pname"
                  v-model="staffObj.first_name"
                  placeholder="Leslee"
                  type="text"
                  name="pname"
                  :class="errors.length ? 'error' : ''"
                >
                <p class="validation error mt-2">
                  {{ errors[0] }}
                </p>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="Staf email"
                rules="email|required"
                class="form-group"
              >
                <label for="pmail">Staff Email</label>
                <input
                  id="pmail"
                  v-model="staffObj.email"
                  placeholder="lesleemoss@practice.com"
                  type="text"
                  name="pmail"
                  :class="errors.length || staffObj.emailErrors.length > 0 ? 'error' : ''"
                >
                <p class="validation error mt-2">
                  {{ errors[0] }}
                </p>
                <p
                  v-for="(err, errIdx) in staffObj.emailErrors"
                  :key="errIdx"
                  class="validation error mt-2"
                >
                  {{ err }}
                </p>
              </validation-provider>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-5 aside-sticky">
        <h5>Send Invite Email</h5>
        <div class="aside-box">
          <div class="row">
            <div class="col-sm-12">
              <p>
                The new pratice member will receive a welcome email with a link that will allow them to create a password.
                Once they've created their password, they will have access to RegimenPro
                <sup>®</sup>.
              </p>
              <a
                href="#"
                class="btn btn-primary"
                @click.prevent="send"
              >Send Invite Email</a>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ValidationProvider,
  ValidationObserver,
  extend
} from 'vee-validate';
import {
  staff,
  physician
} from '@/store/practice/initialObject';
import {
  ADD_STAFF,
  ADD_PHYSICIAN
} from '@/store/practice/actions.types';

import ImagePicker from '@/views/components/ImagePicker';
import {
  SUPER_ADMIN_GET_PRACTICE
} from '@/store/practice/actions.types';
import ApiService from '@/services/api.service';

extend('required', {
  message: 'This field is  required'
});

// Component to invite staff or physican user
// @group Practice
export default {
  name: 'InviteMain',
  components: {
    ValidationProvider,
    ValidationObserver,
    ImagePicker,
  },
  data() {
    return {
      radioSelect: 'physician',
      sending: false,
      staffObj: {},
      physicianObj: {},
      myFiles: [],
      userAvatar: '',
      userAvatarPreview: '',
      cropperOpen: false,
      allegranError: false,
      practiceData: null,
    };
  },

  computed: {
    ...mapGetters([
      'currentUser',
      'practice',
      'staff',
      'physicians',
      'allegran'
    ])
  },

  beforeMount() {
    this.staffObj = {...staff};
    this.physicianObj = {...physician};

    if (this.isRole('super_admin') && ApiService.practiceId) {
      this.$store.dispatch(SUPER_ADMIN_GET_PRACTICE)
        .then(resp => {
          this.practiceData = resp.data.practice;
        });
    }
  },
  methods: {
    // @vuese
    // Used to check which type of user we saving
    send() {
      if(this.radioSelect == 'staff') {
        this.submit();
      }
      if(this.radioSelect == 'physician') {
        this.submitP();
      }
    },
    // @vuese
    // Used to call validation of staff user and create user
    async submit() {
      const valid = await this.isValid();
      if (valid) {
        this.sending = true;
        const data = this.staffObj;
        this.$store.dispatch(ADD_STAFF, data)
          .then(responses => {
            document.needRefresh = true;
            this.$router.push({name: 'MyPractice'});
            this.flashSuccess('An account invite has been sent via email. If you do not see the invitation in a few minutes, please <a href="https://regimenpro.com/pages/affiliate-contact-us">contact us</a> for additional information');
          })
          .catch(err => {
            const errors = err.data.errors;
            const data = JSON.parse(err.config.data);
            const errEmail = data.email;

            if (errors.email && this.staffObj.email == errEmail) {
              this.staffObj.emailErrors = errors.email;
            }

            if(errors.base) {
              this.$router.push({name: 'MyPractice'});
              this.flashError(errors.base[0]);
            }
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },
    // @vuese
    // Used to call validation of physican user and allergan and create user
    async submitP() {
      const valid = await this.isValid();
      const validAllergan = this.isValidAllergan();

      if (valid && validAllergan) {
        this.sending = true;
        const data = {data: this.prepareData(this.physicianObj)};
        this.$store.dispatch(ADD_PHYSICIAN, data)
          .then(responses => {
            document.needRefresh = true;
            this.$router.push({name: 'MyPractice'});
            this.flashSuccess('An account invite has been sent via email. If you do not see the invitation in a few minutes, please <a href="https://regimenpro.com/pages/affiliate-contact-us">contact us</a> for additional information');
          })
          .catch(err => {
            const errors = err.data.errors;
            const data = err.config.data;
            const errEmail = data.get('physician[email]');

            if (errors.email) {
              if (this.physicianObj.email == errEmail) {
                this.physicianObj.emailErrors = errors.email;
              }
            }
            
            if(errors.base) {
              this.$router.push({name: 'MyPractice'});
              this.flashError(errors.base[0]);
            }
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },
    // @vuese
    // Used to check if allergan data valid
    isValidAllergan(){
      if (this.physicianObj.uniqueAllegran){
        if((this.physicianObj.allegran.soldto == '' && this.physicianObj.allegran.shipto == '') || (this.physicianObj.allegran.soldto != '' && this.physicianObj.allegran.shipto != '')){
          return true;
        }else{
          this.allegranError = true;
          return false;
        }
      }else{
        return true;
      }
    },
    // @vuese
    // Used to hide allergan errors
    changeAllergan() {
      this.allegranError = false;
    },
    // @vuese
    // Used to check if form is valid
    async isValid() {
      if (this.radioSelect === 'staff' && this.$refs.staffForm) {
        return await this.$refs.staffForm.validate(); // Validate Staff form
      } else if (this.radioSelect === 'physician' && this.$refs.form) {
        return await this.$refs.form.validate(); // Validate Physician form
      }
      return false; // Return false if no form is selected or refs are not available
    },
    // @vuese
    // Used to show tooltip
    // @arg HTML element data type
    hover(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.add('open');
    },
    // @vuese
    // Used to hide tooltip
    // @arg HTML element data type
    hoverOff(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.remove('open');
    },
    // @vuese
    // Used to prepare data for saving
    prepareData(physician) {
      let formData = new FormData();
      if (!physician.avatar.hasOwnProperty('url') && physician.avatar)
        formData.append('physician[avatar]', physician.avatar, physician.avatarFilename);

      formData.append('physician[email]', physician.email);
      formData.append('physician[first_name]', physician.first_name);

      if (physician.uniqueAllegran) {
        formData.append(
          'physician[allegran][soldto]',
          physician.allegran.soldto
        );
        formData.append(
          'physician[allegran][shipto]',
          physician.allegran.shipto
        );
      }
      return formData;
    },
    // @vuese
    // Used to check if practice selected when current use is super admin
    superAdminShowPractice() {
      return this.isRole('super_admin') ? ApiService.practiceId && this.practiceData : true;
    },

    // Method to reset form validation and switch between forms
    handleRadioChange() {
      this.resetValidation();
      // Additional logic if needed when switching forms
    },

    resetValidation() {
      if (this.$refs.staffForm) {
        this.$refs.staffForm.reset(); // Reset validation for the Staff form
      }
      if (this.$refs.form) {
        this.$refs.form.reset(); // Reset validation for the Physician form
      }
    }
  }
};
</script>