<template>
  <!-- + Practice allergan APP -->
  <div class="wrap-table border-table">
    <div class="row row-group row-group-loader-center last-row">
      <div 
        v-if="sending"
        class="preloader"
      >
        <i />
      </div>
      <div class="col-sm-3">
        <span class="text-up"><strong>APP SOLDTO</strong></span>
      </div>
      <div class="col-sm-9 pr-0">
        <!-- View Info -->
        <div 
          v-if="!edits.allerganApp.show" 
          class="row-large allergan"
        >
          <div class="col-sm-9">
            <span v-if="practice.allegran.soldto">{{ practice.allegran.soldto }}</span>
            <span v-else>Not Connected</span>
          </div>
          <div class="col-md-3 action-box">
            <a
              v-if="canUserEdit" 
              class="link_action"
              href="#"
              data-block="allerganApp" 
              @click.prevent="editPrac"
            >Edit</a>
          </div>
        </div>
        <!-- Edit Info -->
        <div 
          v-else 
          class="col-md-12 form_allergan pr-0"
        >
          <input
            id="soldto"
            v-model="edits.allerganApp.soldTo" 
            type="text"
            :class="allerganError && edits.allerganApp.soldTo == '' ? 'error' : ''" 
            name="soldto"
            @change="changeAllergan" 
          >
        </div>
      </div>
      <div class="col-sm-3">
        <span class="d-block pt-sm-2 pt-3 text-up"><strong>APP SHIPTO</strong></span>
      </div>
      <div class="col-sm-9 pr-0">
        <!-- View Info -->
        <div 
          v-if="!edits.allerganApp.show" 
          class="row-large allergan"
        >
          <div class="col-sm-9">
            <span 
              v-if="practice.allegran.shipto" 
              class="d-block  pt-sm-3"
            >{{ practice.allegran.shipto }}</span>
            <span 
              v-else 
              class="d-block  pt-sm-3"
            >Not Connected</span>
          </div>
        </div>
        <!-- Edit Info -->
        <div 
          v-else 
          class="col-md-12 form_allergan pr-0"
        >
          <input
            id="shipto"
            v-model="edits.allerganApp.shipTo" 
            type="text"
            :class="allerganError && edits.allerganApp.shipTo == '' ? 'error' : ''"
            name="shipto" 
            class="mb-3 mt-sm-2" 
            @change="changeAllergan" 
          >
          <p 
            v-if="allerganError" 
            class="validation error mt-2"
          >
            Please enter your allergan identifier.
          </p>
          <div class="text-right">
            <a 
              href="#"
              class="btn btn-bordered mr-2"
              data-action="cancel"
              @click.prevent="editPracAllerganApp"
            >Cancel</a>
            <a 
              href="#" 
              class="btn btn-primary autowidth"
              data-action="save"
              @click.prevent="editPracAllerganApp"
            >Save</a>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :show-modal="showCancelModal"
      modal-id="before"
      class="modal fade dashboard-modal" 
      @hide-modal-window="showCancelModal = false"
    >
      <img 
        src="../../../../images/warning.png" 
        alt="Warning!" 
        class="warning-icon"
      >
      <h4 class="modal-title text-center">
        Before you go ...
      </h4>
      <p class="text-center">
        You have unsaved changes. Are you sure you want to continue?
      </p>
      <div class="mb-3">
        <a 
          href="#" 
          class="btn btn-primary w-100"
          @click.prevent="hideModal" 
        >Continue Without Saving</a>
      </div>
      <div class>
        <a
          href="#"
          class="btn btn-bordered w-100"
          data-dismiss="modal"
          @click.prevent="showCancelModal = false"
        >Cancel</a>
      </div>
    </Modal>
    <div 
      v-if="showCancelModal" 
      class="modal-backdrop fade show"
    />
  </div>
  <!-- End Practice allergan APP -->
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../partials/Modal';
import { UPDATE_PRACTICE } from '@/store/practice/actions.types';

// Component to show and update allegran data
// Includes Sold to and ship to values
// @group Practice
export default {
  name: 'AllerganApp',
  components: {
    Modal
  },
  props: {
    // Property that show is current suer can edit allegran data
    canUserEdit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      edits: {
        allerganApp: {
          show: false,
          soldTo: '',
          shipTo: ''
        }
      },
      showCancelModal: false,
      allerganError: false,
      sending: false
    };
  },

  computed: {
    ...mapGetters(['practice'])
  },
  
  methods: {
    // @vuese 
    // Show edit form and store value in case we do not want to save chenges, 
    // so we can return to what it was
    editPrac() {
      this.edits.allerganApp.soldTo = this.practice.allegran.soldto;
      this.edits.allerganApp.shipTo = this.practice.allegran.shipto;
      this.edits.allerganApp.show = true;
    },

    // @vuese
    // Used to close allergan form and also save data or cancel editing form
    // in case if data was changed and we cancel editing it will trigger modal
    // that tell that you will loose data
    // @arg event
    editPracAllerganApp(event) {
      switch (event.target.getAttribute('data-action')) {
      case 'save':
        if ((this.edits.allerganApp.shipTo != '' && this.edits.allerganApp.soldTo != '') || (this.edits.allerganApp.shipTo == '' && this.edits.allerganApp.soldTo == '')) {
          this.allerganError = false;
          this.sending = true;
          this.$store.dispatch(UPDATE_PRACTICE, {
            practice: {
              allegran: {
                shipto: this.edits.allerganApp.shipTo,
                soldto: this.edits.allerganApp.soldTo
              }
            }
          })
            .then(() => {
              this.edits.allerganApp.show = false;
              this.flashSuccess('Allergan application updated');
              this.sending = false;
            });
        }else{
          this.allerganError = true;
        }
        break;
      case 'cancel':
        if (
          this.edits.allerganApp.soldTo != this.practice.allegran.soldto ||
          this.edits.allerganApp.shipTo != this.practice.allegran.shipto
        ) {
          this.showCancelModal = true;
        } else {
          this.edits.allerganApp.show = false;
        }
        break;
      }
    },
    // @vuese
    // Used to hide errors on input
    changeAllergan() {
      this.allerganError = false;
    },
    // @vuese
    // Used to hide modal and close form
    hideModal() {
      this.showCancelModal = false;
      this.edits.allerganApp.show = false;
    }
  }
};
</script>