<template>
  <div class="front-wrapper">
    <header class="main-header">
      <div class="container container-md-lg">
        <div class="header-wrap wrap-lg">
          <div class="header-logo">
            <router-link
              :to="{
                name: 'userdashboard'
              }"
            >
              <img
                :src="logo"
                alt="Logo"
              >
            </router-link>
          </div>
          <div class="header-right lg-wrap">
            <div class="row justify-content-between mx-0">
              <p>
                Patient Name:
                <strong>{{ patient.name }}</strong>
              </p>
              <p>
                Email Address:
                <strong>{{ patient.email }}</strong>
              </p>
              <p v-if="patient.physician">
                Physician Name:
                <strong>{{ patient.physician.name }}</strong>
              </p>
            </div>
          </div>
          <div class="header-right-link">
            <router-link
              :to="{
                name: 'PatientView',
                params: {
                  id: patient.id
                }
              }"
            >
              Leave Without Saving
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <div class="container mt-3">
      <Notifications />
    </div>

    <div class="frontend-main">
      <div class="container container-md-lg">
        <h2>Create Order</h2>
        <div class="row">
          <div class="col-md-7">
            <ProductList
              v-if="patient.regimen"
              :open-product-modal="openProductModal"
              :selected-products="selectedProducts"
              :regimen-products="regimenProducts"
              :patient="patient"
              @product-selected="productSelected"
              @edit-product-modal="editProductModal"
            />
          </div>
          <div class="col-md-5">
            <h4>Patient's Order</h4>
            <p>Add or remove products from patient's order</p>
            <div class="wrap-table border-table">
              <div
                v-if="selectedProducts.length == 0"
                class="added-products"
              >
                <p>Please add products</p>
              </div>
              <div
                v-for="product in selectedProducts"
                :key="product.id"
                class="row row-group"
              >
                <div class="col-10 pr-0">
                  <div class="row-large">
                    <div class="col-lg-3">
                      <img
                        v-if="product.image"
                        :src="product.image"
                        class="product-img"
                        alt="Product 1"
                      >
                    </div>
                    <div class="col-lg-9 product-actions">
                      <p class="mb-1">
                        <strong v-html="product.title" />
                      </p>
                      <p class="text-soft mb-1">
                        {{ product.vendor }}
                        <span v-if="product.variant_title && product.variant_title != 'Default Title'">| {{ product.variant_title }}</span>
                        <span class="pl-3">${{ formatMoney(calcProductPrice(product)) }}</span>
                      </p>
                      <p
                        v-if="product.frequency"
                        class="text-soft mb-1"
                      >
                        Frequency:
                        <span class="pl-1">
                          <select
                            id="frequency"
                            v-model="product.selling_plan_id"
                            class="select-md"
                            name="frequency"
                          >
                            <option
                              value=""
                            >
                              One Time
                            </option>
                            <option
                              v-for="(plan, valIdx) in product.frequencyOptions"
                              :key="valIdx"
                              :value="plan.id"
                            >
                              {{ plan.name }}
                            </option>
                          </select>
                        </span>
                      </p>
                      <p
                        :class="!product.changeQty ? 'mb- quanty-action' : 'mb-0 quanty-action d-none'"
                      >
                        <span class="text-soft">Quantity:
                          <span>
                            {{ product.quantity }}
                          </span>
                        </span>
                        <a
                          class="change"
                          href="#"
                          @click.prevent="showChangeQty(product)"
                        >
                          Change
                        </a>
                      </p>
                      <div
                        :class="product.changeQty ? 'wrap-actions' : 'wrap-actions d-none'"
                      >
                        <div class="add-actions">
                          <button
                            id="sub"
                            type="button"
                            class="sub"
                            @click="decreseQty(product)"
                          >
                            -
                          </button>
                          <input
                            v-model="product.newQty"
                            type="text"
                            min="0"
                            max="999"
                          >
                          <button
                            id="add"
                            type="button"
                            class="add"
                            @click="increseQty(product)"
                          >
                            +
                          </button>
                        </div>
                        <a
                          class="update"
                          href="#"
                          @click.prevent="changeQty(product)"
                        >
                          Update
                        </a>
                        <a
                          href="#"
                          @click.prevent="hideChangeQty(product)"
                        >Cancel</a>
                      </div>
                      <p v-if="futureOrder">
                        <span class="text-soft">Charge Date:
                          <span>{{ product.next_charge_scheduled_at ? formatDate(product.next_charge_scheduled_at) : 'Today' }}</span>
                        </span>
                        <a
                          class="change"
                          href="#"
                          @click.prevent="changeScheduleDate(product)"
                        >
                          Change
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <!-- <a
                    class="link_action"
                    href="#"
                    @click.prevent="removeProduct(product)"
                  >
                    Remove
                  </a> -->
                  <br>
                  <a
                    class="link_action"
                    href="#"
                    @click.prevent="editProductModal(product)"
                  >
                    Edit
                  </a>
                </div>
              </div>
              <div class="row py-3 text-right">
                <div class="col-lg-9 col-6">
                  <strong>Tax</strong>
                </div>
                <div class="col-lg-3 col-6">
                  <strong>-</strong>
                </div>
                <div class="col-lg-9 col-6">
                  <strong>Subtotal</strong>
                </div>
                <div class="col-lg-3 col-6">
                  <strong>${{ selectedProducts.length > 0 ? formatMoney(calcSubtotal()) : '0' }}</strong>
                </div>
              </div>
              <div class="pt-1">
                <button
                  v-if="!sending"
                  class="btn btn-primary"
                  @click.prevent="checkout"
                >
                  Checkout
                </button>
                <button
                  v-else
                  class="btn btn-primary"
                  disabled
                >
                  processing...
                </button>
              </div>
              <div
                v-if="checkoutError"
                :class="`message warning`"
              >
                <p class="icon">
                  {{ checkoutError }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- /. row-create-account -->
      </div>
    </div>
    <!--/. frontend-main -->
    <Modal
      :show-modal="showModal"
      :modal-type="'popup-lg'"
      modal-id="add_product"
      @hide-modal-window="showModal = false"
    >
      <ProductModal
        :key="showModal"
        :product-id="productId"
        :modal-action="modalAction"
        :selected-product="selectedProduct"
        :submit-text="'Add Product To Order'"
        :remove-text="'Remove from Order'"
        :disable-on-out-of-stock="true"
        @remove-product="removeProduct"
        @hide-modal-window="showModal = false"
        @product-selected="productSelected"
      />
    </Modal>

    <Modal
      :show-modal="showOutOfStock"
      :modal-type="'popup-l'"
      modal-id="out_of_stock_products"
      @hide-modal-window="showOutOfStock = false"
    >
      <OutStock
        :key="showOutOfStock"
        :products="outOfStock"
        :text="`Looks like one or more of the products in your patient's regimen are temporarily out of stock and not available to order`"
        :title="'Product in Regimen Out of Stock'"
        @hide-modal-window="showOutOfStock = false"
      />
    </Modal>

    <Modal
      :show-modal="showNotAvailable"
      :modal-type="'popup-l'"
      modal-id="no_longer_available_products"
      @hide-modal-window="showNotAvailable = false"
    >
      <OutStock
        :key="showNotAvailable"
        :products="notAvailableStock"
        :text="`Looks like one or more of the products in your patient's regimen will no longer be available to order`"
        :title="'Product in Regimen No Longer Available'"
        @hide-modal-window="showNotAvailable = false"
      />
    </Modal>

    <Modal
      :show-modal="showSheduleOrder"
      :modal-type="'popup-l'"
      modal-id="shedule_order"
      @hide-modal-window="showSheduleOrder = false"
    >
      <ScheduleOrder
        :key="showSheduleOrder"
        :product="selectedProduct"
        @add-to-order="addToOrder"
        @hide-modal-window="showSheduleOrder = false"
      />
    </Modal>

    <div
      v-if="showModal || showOutOfStock || showNotAvailable || showSheduleOrder"
      class="modal-backdrop fade show"
    />
  </div>
  <!--/. frontend-wrap -->
</template>

<style scoped>
  #frequency {
    width: calc(100% - 80px);
    display: inline-block;
  }
</style>

<script>
import logo from '@images/logo-light.png';
import { mapGetters } from 'vuex';
import ProductList from '../partials/ProductList';
import Modal from '../partials/Modal';
import ProductModal from '../partials/ProductModal';
import OutStock from './outOfStockProduct';
import ScheduleOrder from '../partials/ScheduleOrder';
import {
  CheckoutService
} from '@/services/api.service';
import {
  formatMoney,
  bulidAddressStr,
  formatMetaModel,
  findMeta,
  getShopifyId
} from '@/helper';
import { ProductService } from '@/services/api.service';
import moment from 'moment';
import axios from 'axios';

// @group Patinet
// Component to create patient orders
export default {
  name: 'PatientCreateOrder',
  components: {
    ProductList,
    ProductModal,
    Modal,
    OutStock,
    ScheduleOrder
  },

  props: {
    // @vuese
    // Patient object
    patient: {
      type: Object,
      default() {
        return {};
      },
    }
  },

  data() {
    return {
      sending: false,
      logo,
      showModal: false,
      productId: null,
      patientId: null,
      selectedProducts: [],
      regimenProducts: [],
      selectedProduct: {},
      modalAction: 'create',
      checkoutError: '',
      showOutOfStock: false,
      outOfStock: [],
      showNotAvailable: false,
      showSheduleOrder: false,
      notAvailableStock: [],
      frequencyOptions: []
    };
  },

  computed: {
    ...mapGetters(['practice', 'currentUser']),
    futureOrder() {
      return Array.isArray(this.practice) ?
        this.patient.practice ? this.patient.practice.future_order : false :
        this.practice.future_order;
    }
  },

  watch: {
    patient: {
      handler: 'fillData',
      immediate: true,
    }
  },
  methods: {
    // @vuese
    // Used to convert address object to string, if object have empty  props
    // return word `None`
    // @arg address object
    bulidAddressStr,

    // @vuese
    // Used to remove product from selected products
    // @arg Product object
    removeProduct(value) {
      this.showModal = false;
      const productIndex = this.findIdx(value);

      if (productIndex !== -1) {
        this.selectedProducts.splice(productIndex, 1);
      }
    },

    // @vuese
    // Used to call function to add product to order or
    // call funtion to pass data to schedule modal,
    // if this feature is enabled
    // @arg Product object
    productSelected(product) {
      this.showModal=false;

      // Don't add product with active subscription to order
      if (product.subscription && product.subscription.status == 'active') return;

      product.newQty = 1;
      product.quantity = 1;
      product.changeQty = false;
      if (!product.selling_plan_id){
        product.selling_plan_id = ''
      }

      if (!product.frequencyOptions) {
        product.frequency = 'One time';
        product.recomendation_type = 'Core Product';
        product.time_of_use = 'Per Package Instructions';
        this.fillMeta(product);
      }

        this.getSellingPlan(product);
        this.addToOrder(product);
    },

    // @vuese
    // Used  to pass data to schedule modal
    // @arg Product object
    prepareProductForSchedule(product) {
      this.showSheduleOrder = true;
      this.selectedProduct = product;
    },

    getSellingPlan(product) {
      ProductService.getSellingPlanByVariant(product.handle)
        .then(resp => {
          
          if(resp.data){
            let sellingPlansData = [];
            if (
              resp.data.data &&
              resp.data.data.product &&
              resp.data.data.product.sellingPlanGroups &&
              resp.data.data.product.sellingPlanGroups.edges.length > 0 &&
              resp.data.data.product.sellingPlanGroups.edges[0].node.sellingPlans
            ) {
              this.frequencyOptions = resp.data.data.product.sellingPlanGroups.edges[0].node.sellingPlans.edges.map(plan => ({
                id: plan.node.id,
                name: plan.node.name
              }));
              this.$set(product, 'frequencyOptions', this.frequencyOptions);
            }
          }
        })
        .finally(() => {
          this.sending = false;
        });
    },

    // @vuese
    // Used to add prouct to order
    // @arg Product object
    addToOrder(product) {
      this.showSheduleOrder = false;
      const productIndex = this.findIdx(product);
      if (productIndex != -1)
        this.selectedProducts.splice(productIndex, 1, product);
      else
        this.selectedProducts.push(product);
    },

    // @vuese
    // Used to change product next change date
    // @arg Product object
    changeScheduleDate(product) {
      this.selectedProduct = product;
      this.showSheduleOrder = true;
    },

    // @vuese
    // Used to to calculate product price, by multiply Product price with
    // product quantity
    // @arg Product object
    calcProductPrice(product) {
      return parseFloat(product.price) * product.quantity;
    },

    // @vuese
    // Calc total price of all products
    calcSubtotal() {
      return this.selectedProducts.reduce((sum, product) => {
        return sum + this.calcProductPrice(product);
      }, 0);
    },

    // @vuese
    // Used format money
    // @arg Price value
    formatMoney (val) {
      return formatMoney(val);
    },


    // @vuese
    // Used to open product modal in create mode and pass product ID to it
    // @arg Product ID
    openProductModal(productId) {
      this.productId = productId;
      this.modalAction = 'create';
      this.selectedProduct = null;
      this.showModal = true;
    },

    // @vuese
    // Used to open product modal in update mode  and pass product to it
    // @arg Product object
    editProductModal(selectedProduct) {
      this.productId = selectedProduct.shopify_product_graph_id;
      this.selectedProduct = selectedProduct;
      if (selectedProduct.subscription && selectedProduct.subscription.status == 'active') {
        this.selectedProduct.patient = this.patient;
        this.selectedProduct.subscriptionObj = selectedProduct.subscription;
      }
      this.modalAction = 'update';
      this.showModal = true;
    },

    // @vuese
    // Used to check if there at least one product and if so, redirect to
    // next page
    async checkout() {
      if (this.selectedProducts.length == 0) {
        this.checkoutError = 'Please add at least one product';
      } else {
        let items = this.selectedProducts.map(item => ({
          merchandiseId: item.variant_graph_id,
          quantity: item.quantity,
          sellingPlanId: item.selling_plan_id,
          patientId: this.patient.id,
          physicianId: this.currentUser.id
        }));
        ProductService.createCheckoutFor(items)
          .then(resp => {
            if(resp.data){
              CheckoutService.process({
                web_url: resp.data.data.cartCreate.cart.checkoutUrl
                }, this.patient.id, this.getRequestPracticeId()
              ).then(resp => {
                if (resp.data.url) {
                  window.location.href = resp.data.url;
                }else{
                  this.checkoutError = resp.data.error;
                }
              });
            }
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },

    getRequestPracticeId() {
      return this.isRole('super_admin') ? `/${this.patient.practice.id}` : '';
    },

    // @vuese
    // Used to change product quantity
    // @arg Product object
    changeQty(value) {
      const productIndex = this.findIdx(value);
      if (productIndex !== -1) {
        this.selectedProducts[productIndex].changeQty = false;
        if(this.selectedProducts[productIndex].newQty == 0){
          this.selectedProducts.splice(productIndex, 1);
          this.$forceUpdate();
        }else{
          this.selectedProducts[productIndex].quantity = this.selectedProducts[productIndex].newQty;
          this.$forceUpdate();
        }
      }
    },

    // @vuese
    // Used to show product change quantity box
    // @arg Product object
    showChangeQty(value) {
      const productIndex = this.findIdx(value);
      if (productIndex !== -1) {
        this.selectedProducts[productIndex].changeQty = true;
        this.selectedProducts[productIndex].newQty = this.selectedProducts[productIndex].quantity;
        this.$forceUpdate();
      }
    },

    // @vuese
    // Used to hide product change quantity box
    hideChangeQty(value) {
      const productIndex = this.findIdx(value);
      if (productIndex !== -1) {
        this.selectedProducts[productIndex].changeQty = false;
        this.selectedProducts[productIndex].newQty = this.selectedProducts[productIndex].quantity;
        this.$forceUpdate();
      }
    },

    // @vuese
    // Used to decrese product quantity
    // @arg Product object
    decreseQty(value) {
      const productIndex = this.findIdx(value);
      if (productIndex !== -1) {
        if (this.selectedProducts[productIndex].newQty == 0) return;
        this.selectedProducts[productIndex].newQty--;
        this.$forceUpdate();
      }
    },

    // @vuese
    // Used to increse product quantity
    // @arg Product object
    increseQty(value) {
      const productIndex = this.findIdx(value);
      if (productIndex !== -1) {
        this.selectedProducts[productIndex].newQty++;
        this.$forceUpdate();
      }
    },

    // @vuese
    // Used to fine product index in selected products array
    // @arg Product object
    findIdx(value) {
      return this.selectedProducts.findIndex(product => {
        return product.variant_graph_id == value.variant_graph_id;
      });
    },

    // @vuese
    // Used to load additional data to product from shopify
    // @arg Product object
    fillMeta(product) {
      ProductService.getProductMetaByHandle(product.handle)
        .then(resp => {
          if (resp.data.data.productByHandle) {
            product.meta = formatMetaModel(resp.data.data.productByHandle.metafields);
            const usageInstruction = findMeta(product.meta, 'detail', 'usage_instruction');
            if (usageInstruction) product.usage_instruction = usageInstruction.value;

          }
        });
    },

    // @vuese
    // Used to fill local store data
    fillData() {
      this.patientId = this.patient.id;
      if (this.patient.regimen && this.patient.regimen.products.length) {
        this.regimenProducts = this.patient.regimen.products.filter(product => {
          // Remove product if not available
          return product.available ? true : false;
        });

        this.patient.regimen.products.forEach(product => {
          if (!product.hasOwnProperty('qty')) {
            product.quantity = 1;
            product.newQty = 1;
            product.changeQty = false;
          }

          product.frequencyOptions = [];
          this.fillMeta(product);
          this.getSellingPlan(product);

          // Remove product if not available
          if (product.available == false) {
            this.notAvailableStock.push(product);
            this.removeProduct(product);
          }
          // Show not available if have one
          if (this.notAvailableStock.length)
            this.showNotAvailable = false;

          // Remove product if out of stock
          if (product.in_stock == false) {
            this.outOfStock.push(product);
            this.removeProduct(product);
          }

          // Show out of stock if not showNotAvaliable
          if (this.outOfStock.length && this.showNotAvailable === false)
            this.showOutOfStock = false;
        });
      }
    },

    // @vuese
    // Used to chage format of a date
    // @arg Date value
    formatDate(date) {
      return moment(date).format('YYYY/MM/DD');
    }
  }
};
</script>
